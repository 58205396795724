import React from "react"
import { VictoryChart, VictoryLine, VictoryScatter, VictoryAxis, VictoryTheme, VictoryTooltip, VictoryZoomContainer } from 'victory';
import { calculateZScore, formatNumber, formatDate, calculatePercentageAppreciation } from "../../utility/Helper";
import { bucketColor } from "../Comps";
import { CompBucketOption, CompTypeOption } from "../../utility/Global";
import { Grid, Header, Segment, Image } from "semantic-ui-react";
import { mean, max, min, std } from "../../utility/MathHelper";

const getGeoStatus = (code) => {
    if (code === '5')
        return "5 Digit Zip"
    if (code === '7')
        return "Zip + 2"
    if (code === '9')
        return "Zip + 4"
    if (code === 'A')
        return "Interpolated Rooftop Level"
    if (code === 'B')
        return "Rooftop Level"
    if (code === 'X')
        return "Not Coded"
}

export const getTDSChartData = (lot, color, symbol = 'circle') => {
    let { altColors } = lot
    return {
        x: lot.ltds ?? 0,
        y: lot.pricePerSqft,
        symbol,
        altColors: {
            ...altColors,
            color
        },
        color,
        bucket: lot.bucket,
        address: lot.address,
        likenessScore: lot.composyteLikenessScore,
        timeDistanceScore: lot.composyteTimeDistanceScore,
        sizeScore: lot.composyteSizeScore,
        longitude: lot.longitude,
        latitude: lot.latitude,
        documentAmount: lot.documentAmount,
        previousSaleAmount: lot.previousSaleAmount,
        saleAmount: lot.saleAmount,
        held: lot.heldString,
        flipped: lot.withinTwoYears,
        purchaseMortgage: lot.isPurchaseMortgage,
        documentDate: lot.documentDate,
        //ltvValue: lot.ltvValue,
        documentType: lot.compType,
        buyers: lot.buyers,
        sellers: lot.sellers,
        buyer: lot.buyers?.length > 0 ? lot.buyers[0].name : '',
        seller: lot.sellers?.length > 0 ? lot.sellers[0].name : '',
        lenders: lot.firstMortgageParties?.filter(x => x.type === 2),
        borrowers: lot.firstMortgageParties?.filter(x => x.type === 1),
        listingAgent: lot.listingAgent,
        bldgArea: lot.bldgArea,
        lotArea: lot.lotArea,
        bucketZScore: lot.bucketZScore,
        neighborhood: lot.neighborhood,
        bedrooms: lot.bedrooms ? (Array.isArray(lot.bedrooms) ? lot.bedrooms.join(",") : lot.bedrooms) : "",
        composyteBuckets: lot.composyteBuckets,
        finalBuckets: lot.finalBuckets,
        versions: lot.versions,
        geoStatus: getGeoStatus(lot.geoStatusCode)
    }
}

function filterOutAnythingHigh(data, zScoreThreshold = 3) {
    // Get initial Y values (positive only)
    const allY = data.filter(p => p.y > 0).map(p => p.y);
    if (allY.length === 0) return data;
  
    const avgY = mean(allY);
    const yStd = std(allY);
  
    // Precompute z-scores and flags
    data.forEach(p => {
      p.zScore = calculateZScore(p.y, avgY, yStd);
      p.hide = (
        (p.x <= 0 && !p.bucket) ||
        (p.zScore !== undefined && Math.abs(p.zScore) > zScoreThreshold && !p.bucket)
      );
    });
  
    // Filter: hide is false, zScore within range, and bucketZScore acceptable
    const filtered = data.filter(p => {
      const zOk = p.zScore !== undefined && Math.abs(p.zScore) <= zScoreThreshold;
      const bucketOk = (
        p.bucketZScore == null ||
        p.bucketZScore === 0 ||
        (p.bucketZScore >= -3 && p.bucketZScore <= 3)
      );
      return !p.hide && zOk && bucketOk;
    });
  
    return filtered;
  }
  

const TDSGridRow = ({ title, value, style }) => (
    <Grid.Row style={style}>
        <Grid.Column width='4'>
            {title && <span>{title}:</span>}
        </Grid.Column>
        <Grid.Column width='12'>
            <span>{value}</span>
        </Grid.Column>
    </Grid.Row>
)

const FlyOut = ({ x, y, datum, dx, dy }) => {
    let offset = 25
    let width = 400
    let height = 750
    //console.log(x, y, dx, dy)

    let xPos = x + offset
    let yPos = y - 150

    //console.log(xPos, yPos)

    if (xPos + width >= window.innerWidth - offset) {
        xPos = window.innerWidth - (offset * 2) - width
    }
    if (xPos <= offset) {
        xPos = offset
    }

    if (yPos + height >= window.innerHeight - offset) {
        yPos = window.innerHeight - (offset * 2) - height
    }
    if (yPos <= offset) {
        yPos = offset
    }

    //console.log(xPos, yPos)
    return <g style={{ pointerEvents: 'none' }}>
        <foreignObject className='popup-tds-container' x={xPos} y={yPos} width={width} height={height}>
            <Segment>
                <Header color='olive' textAlign='center' as='h3'>{datum.address}</Header>
                <Grid className="popup-tds-list" textAlign='left' columns={2}>
                    {
                        datum.documentType === CompTypeOption.DEED ? (
                            <>
                                {datum.sellers?.map((s, index) => {
                                    //const title = index === 0 ? `${datum.documentType === CompTypeOption.DEED ? 'Seller' : datum.documentType === CompTypeOption.MORTGAGE ? 'Borrower' : datum.documentType === CompTypeOption.RENT || datum.documentType === CompTypeOption.LISTING ? 'Agent' : ''}` : ''
                                    return <TDSGridRow style={index === 0 && datum.sellers.length > 1 ? { marginBottom: '-1.5rem' } : null} title={"Seller"} value={s?.name} />
                                })
                                }</>
                        ) : null
                    }

                    {
                        datum.documentType === CompTypeOption.DEED ? (
                            <>
                                {datum.buyers?.map((b, index) => {
                                    //const title = index === 0 ? `${datum.documentType === CompTypeOption.DEED ? 'Seller' : datum.documentType === CompTypeOption.MORTGAGE ? 'Borrower' : datum.documentType === CompTypeOption.RENT || datum.documentType === CompTypeOption.LISTING ? 'Agent' : ''}` : ''
                                    return <TDSGridRow style={index === 0 && datum.buyers.length > 1 ? { marginBottom: '-1.5rem' } : null} title={"Buyer"} value={b?.name} />
                                })
                                }</>
                        ) : null
                    }

                    {
                        datum.documentType === CompTypeOption.MORTGAGE ? (
                            <>
                                {datum.borrowers?.map((l, index) => {
                                    //const title = index === 0 ? `${datum.documentType === CompTypeOption.DEED ? 'Seller' : datum.documentType === CompTypeOption.MORTGAGE ? 'Borrower' : datum.documentType === CompTypeOption.RENT || datum.documentType === CompTypeOption.LISTING ? 'Agent' : ''}` : ''
                                    return <TDSGridRow style={index === 0 && datum.borrowers.length > 1 ? { marginBottom: '-1.5rem' } : null} title={"Borrower"} value={l?.name} />
                                })
                                }</>
                        ) : null
                    }

                    {
                        datum.documentType === CompTypeOption.MORTGAGE ? (
                            <>
                                {datum.lenders?.map((l, index) => {
                                    //const title = index === 0 ? `${datum.documentType === CompTypeOption.DEED ? 'Seller' : datum.documentType === CompTypeOption.MORTGAGE ? 'Borrower' : datum.documentType === CompTypeOption.RENT || datum.documentType === CompTypeOption.LISTING ? 'Agent' : ''}` : ''
                                    return <TDSGridRow style={index === 0 && datum.lenders.length > 1 ? { marginBottom: '-1.5rem' } : null} title={"Lender"} value={l?.name} />
                                })
                                }</>
                        ) : null
                    }

                    {
                        datum.documentType === CompTypeOption.LISTING || datum.documentType === CompTypeOption.RENT  ? (
                            <>
                                <TDSGridRow title={"Agent"} value={datum.listingAgent?.name} />
                            </>
                        ) : null
                    }
                    <TDSGridRow title='Amount' value={`$${formatNumber(datum.documentAmount)} ${datum.flipped && datum.documentType !== CompTypeOption.RENT ? '(F)' : ''}`} />
                    {/* {datum.ltvValue && <TDSGridRow title='LTV' value={`$${formatNumber(datum.ltvValue)}`} />} */}
                    <TDSGridRow title='Date' value={formatDate(datum.documentDate)} />
                    {datum.documentType === CompTypeOption.DEED && datum.previousSaleAmount > 0 && <TDSGridRow title='Previous' value={`$${formatNumber(datum.previousSaleAmount)} (${formatNumber(calculatePercentageAppreciation(datum.previousSaleAmount, datum.saleAmount))}%)`} />}
                    {datum.documentType === CompTypeOption.DEED && datum.held && <TDSGridRow title='Held' value={`${datum.held}`} />}
                    <TDSGridRow title={`${datum.documentType === CompTypeOption.DEED || datum.documentType === CompTypeOption.LISTING ? 'PPSF' : datum.documentType === CompTypeOption.MORTGAGE ? 'LPSF' : datum.documentType === CompTypeOption.RENT ? 'RPSF' : ''}`} value={`$${formatNumber(datum.y)}`} />
                    <TDSGridRow title='LTDS' value={`${formatNumber(datum.x)}%`} />
                    <TDSGridRow title='L,TD,S' value={`${formatNumber(datum.likenessScore)}%, ${formatNumber(datum.timeDistanceScore)}%, ${formatNumber(datum.sizeScore)}%`} />
                    <TDSGridRow title='SQFT' value={`${formatNumber(datum.bldgArea)}`} />
                    <TDSGridRow title='LotSQFT' value={`${formatNumber(datum.lotArea)}`} />
                    {datum.bedrooms && <TDSGridRow title='Bedrooms' value={datum.bedrooms} />}
                    {datum.neighborhood && <TDSGridRow title='Neighborhood' value={datum.neighborhood} />}
                    {datum.bucket && <TDSGridRow title='Bucket' value={datum.bucket} />}
                    <TDSGridRow title='Z' value={`${formatNumber(datum.bucketZScore)}`} />
                    {datum.composyteBuckets && datum.composyteBuckets.length > 0 && <TDSGridRow title='Composyte' value={datum.composyteBuckets.join(", ")} />}
                    {datum.finalBuckets && datum.finalBuckets.length > 0 && <TDSGridRow title='Final' value={datum.finalBuckets.join(", ")} />}
                    {datum.versions && datum.versions.length > 0 && <TDSGridRow title='Versions' value={datum.versions.join(", ")} />}
                    {datum.geoStatus && <TDSGridRow title='GeoStatus' value={datum.geoStatus} />}
                    {/*<TDSGridRow title='Likeness' value={`${formatNumber(datum.likeness)}%`} />
            <TDSGridRow title='TD' value={`${formatNumber(datum.td)}%`} />
            <TDSGridRow title='Size' value={`${formatNumber(datum.sizeScore)}%`} />
            <TDSGridRow title='PoH' value={`${formatNumber(datum.poh)}%`} />
            <TDSGridRow title='PoL' value={`${formatNumber(datum.pol)}%`} /> */}
                </Grid>
                {/* <List className='popup-tds-list' size='mini'>
        <List.Item>PsF: </List.Item>
         <List.Item>LTDS: {formatNumber(datum.x)}%</List.Item>
         <List.Item>Likeness: {formatNumber(datum.likeness)}%</List.Item>
         <List.Item>TD: {formatNumber(datum.td)}%</List.Item>
         <List.Item>Size Score: {formatNumber(datum.sizeScore)}%</List.Item>
         <List.Item>PoH: {formatNumber(datum.poh)}%</List.Item>
         <List.Item>PoL: {formatNumber(datum.pol)}%</List.Item>
        </List> */}
                <Image size='medium' centered src={`https://maps.googleapis.com/maps/api/streetview?size=600x450&location=${datum.latitude},${datum.longitude}&fov=70&pitch=0&sensor=false&scale=2&key=${process.env.REACT_APP_GOOGLE_API_KEY}`} />
            </Segment>
        </foreignObject>
    </g>
}

function calculateChartViewport(data, padding = 0.1, stdDevThreshold = 2) {
    // Extract valid Y values
    const yValues = data.filter(point => point.y != null).map(point => point.y);
  
    if (yValues.length === 0) {
      return { minY: 0, maxY: 0 };
    }
  
    const avgY = mean(yValues);
    const stdDev = Math.sqrt(mean(yValues.map(y => (y - avgY) ** 2)));
  
    // Filter out outliers
    const filteredY = yValues.filter(y => Math.abs(y - avgY) <= stdDevThreshold * stdDev);
  
    if (filteredY.length === 0) {
      return { minY: 0, maxY: 0 };
    }
  
    const maxY = max(filteredY);
    const minY = min(filteredY);
  
    const deltaMaxY = maxY - avgY;
    const deltaMinY = avgY - minY;
    const maxIsLarger = deltaMaxY > deltaMinY;
  
    let viewportMaxY, viewportMinY;
  
    if (maxIsLarger) {
      viewportMaxY = maxY;
      viewportMinY = avgY - deltaMaxY;
    } else {
      viewportMinY = minY;
      viewportMaxY = avgY + deltaMinY;
    }
  
    const range = viewportMaxY - viewportMinY;
    const paddingAmount = range * padding;
  
    return {
      minY: Math.max(viewportMinY - paddingAmount, 0),
      maxY: viewportMaxY + paddingAmount
    };
  }
  

const LTDSChart = ({ chartData, extraData = null, colorKey = 'color', showOutliers = false, minX = 0, cHeight = 450, cWidth = 450, scatterSize = 3.5 }) => {
    let { data, stdInfo, movingAverage } = chartData
    data = data.filter(_ => _.x >= minX && _.y)
    if (!showOutliers)
        data = filterOutAnythingHigh(data)
    if (extraData) data.push(...extraData)

    const { low, avg, high, lowAvg, highAvg, avgLTDS, lowestLTDS } = stdInfo || {}
    const { minY: minYToUse, maxY: maxYToUse } = calculateChartViewport(data);

    //let offset = avgY
    //console.log(offset)
    //maxYToUse += offset
    //minYToUse -= offset

    let lowBucket = {
        color: bucketColor(CompBucketOption.LOW),
        data: [
            { x: minX, y: low ?? 0 },
            { x: 100, y: low ?? 0 }
        ]
    }

    let middleBucket = {
        color: 'grey',
        data: [
            { x: minX, y: avg ?? 0 },
            { x: 100, y: avg ?? 0 }
        ]
    }

    let highBucket = {
        color: bucketColor(CompBucketOption.HIGH),
        data: [
            { x: minX, y: high ?? 0 },
            { x: 100, y: high ?? 0 }
        ]
    }

    let quarterLowBucket = {
        color: bucketColor(CompBucketOption.MIDDLELOW),
        data: [
            { x: minX, y: lowAvg ?? 0 },
            { x: 100, y: lowAvg ?? 0 }
        ]
    }

    let quarterHighBucket = {
        color: bucketColor(CompBucketOption.MIDDLEHIGH),
        data: [
            { x: minX, y: highAvg ?? 0 },
            { x: 100, y: highAvg ?? 0 }
        ]
    }

    let avgLTDSLine = {
        color: "#ffc0cb",
        data: [
            { x: avgLTDS ?? 0, y: minYToUse },
            { x: avgLTDS ?? 0, y: maxYToUse }
        ]
    }

    let lowestLTDSLine = {
        color: "#ffa500",
        data: [
            { x: lowestLTDS ?? 0, y: minYToUse },
            { x: lowestLTDS ?? 0, y: maxYToUse }
        ]
    }

    let bucketLines = [lowBucket, quarterLowBucket, middleBucket, quarterHighBucket, highBucket, avgLTDSLine, lowestLTDSLine]
    //console.log(minYToUse, maxYToUse)
    return (
        <VictoryChart
            width={cWidth}
            height={cHeight}
            theme={VictoryTheme.material}
            containerComponent={<VictoryZoomContainer />}
        //domain={{ x: [0, 5], y: [0, 7] }}
        >
            <VictoryAxis
                //width={400}
                //height={400}            
                label='LTDS Score'
                domain={[minX - 5, 105]}
                theme={VictoryTheme.material}
                //offsetY={200}
                standalone={false}
                style={{
                    //tickLabels: {fontSize: 6, padding: 8},
                    axisLabel: { padding: 40 }
                }}
                tickFormat={(t) => `${t}%`}
            />
            <VictoryAxis dependentAxis
                //width={400}
                //height={400}
                minDomain={0}
                label='PPSF'
                domain={[minYToUse, maxYToUse]}
                theme={VictoryTheme.material}
                offsetX={cWidth / 2}
                standalone={false}
                style={{
                    tickLabels: { padding: ((cWidth / 2) - 50) },
                    axisLabel: { padding: ((cWidth / 2) - 12.5) },
                }}
                tickCount={10}
                tickFormat={(t) => t < 0 ? '' : `$${t}`}
            />

            {bucketLines.map((_, index) => {
                return <VictoryLine
                    key={index}
                    animate={{
                        duration: 1000,
                        onLoad: { duration: 500 }
                    }}
                    interpolation='linear'
                    style={{
                        data: { strokeWidth: 1, stroke: _.color },
                        //parent: { border: `11px solid #ccc`}
                        //labels:{fill: _.color}
                    }}
                    data={_.data}
                //labelComponent={<VictoryLabel renderInPortal dy={-20}/>}
                />
            })}
          
            <VictoryScatter
                style={{
                    data: {
                        fill: ({ datum }) => datum.altColors[colorKey] ?? '#ececec'
                    }
                }}
                size={scatterSize}
                data={data}
                //sortKey={colorKey}
                labelComponent={<VictoryTooltip
                    constrainToVisibleArea
                    pointerOrientation="bottom"
                    //flyoutStyle={{ fill: '#384353', stroke: "tomato", strokeWidth: 2 }}
                    //style={{ fill: "white", fontSize: 9 }}
                    //flyoutPadding={5}
                    //flyoutHeight={100}
                    //flyoutWidth={150}
                    cornerRadius={10}
                    pointerLength={10}
                    flyoutComponent={<FlyOut />}
                />}
                labels={() => ''}
        />





        </VictoryChart>
    )
}

export default React.memo(LTDSChart)