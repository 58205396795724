import React, { useEffect, useState } from 'react';

import '../../assets/css/Valuation.css'

import { Button, Form, Grid, Segment, Divider, Dimmer, Loader, Checkbox } from 'semantic-ui-react'
import { property_api_rents_url, property_api_get_neighborhood_sales_url, property_api_annual_tax_url, property_api_get_neighborhood_loans_url, parcel_search_url, property_api_get_lot_url } from '../../utility/Config';
import { getFredPrices, getInterestRates, makeGetRequest } from '../../utility/Services';
import { runComps, bucketColor, runAllComps } from '../Comps';
import { getChartMovingAverage } from '../charts/ChartHelper';
import { getSalesForNeighborhood } from '../Neighborhoods';
import { onlyUnique, property, flatten, isEmpty, calculatePayment, calculatePmt2Loan, roundToNearest, getParcelAddressQueryParams, roundToFloorByNearest } from '../../utility/Helper';
import { CompTypeOption, CompBucketOption, CLOSING_COST_PERCENTAGE, SOFT_COST_PERCENTAGE, BROKER_FEES_PERCENTAGE, SALES_COST_PERCENTAGE, PURCHASE_COST_PERCENTAGE } from '../../utility/Global';
import SaleChart from '../charts/SaleChart';
import LTDSChart, { getTDSChartData } from '../charts/LTDSChart';

import rent_figures from '../../assets/json/rent_figures.json'
import section_eight_figures from '../../assets/json/section_eight_figures.json'
import { FormattedInputType, MoneyFormattedInput, NumberFormattedInput, PercentFormattedInput } from '../extras/InputElements';
import { ValueContainer } from '../extras/ValueContainer';
import MapboxMap from '../maps/MapboxMap';
import BucketCalc from '../../BucketCalc';
import Lot from '../../Tax-Lot';
import SearchInput from '../extras/SearchInput';

rent_figures.sort((a, b) => a.value < b.value ? 1 : -1)

const VERSION = 14
const ALL_VERSIONS = [9,10,11,12]

const LoadState = {
  NONE: 0,
  SALES: 1,
  FINANCING: 2,
  RENTALS: 3,
  FINISHING: 4,
  FINISHED: 5
}

function getRentForRooms(rooms) {
  const rates = section_eight_figures.filter(_ => _.bedrooms === rooms)
  if (rates.length === 0)
    return 0
  return rates[0].value
}

function getRentalRateUnitModifier(unit) {
  switch (unit) {
      case 1:
          return -.15
      case 2:
          return -.05
      case 3:
          return .1
      case 4:
          return .2
      default:
          return 0
  }
}

function getRentalRate(value, unit) {
  let modifier = getRentalRateUnitModifier(unit)
  let rate = 0
  rent_figures.every(element => {
    if (value >= element.value) {
      rate = element.rate
      return false
    }
    return true
  })

  return ((value * rate) / 12) * (1 + modifier)
}

const PropertyCondition = {
  Poor: 1,
  Fair: 2,
  Excellent: 3
}

const propertyConditionOptions = [
  { key: PropertyCondition.Excellent, text: 'Excellent', value: PropertyCondition.Excellent },
  { key: PropertyCondition.Fair, text: 'Fair', value: PropertyCondition.Fair },
  { key: PropertyCondition.Poor, text: 'Poor', value: PropertyCondition.Poor }
]

function getChartAvgSaleLine(lowX, highX, yValue, color) {
  let data = {
    color: color,
    data: [
      { x: lowX, y: yValue ?? 0 },
      { x: highX, y: yValue ?? 0 }
    ]
  }
  return data
}

const CompFormField = ({ label, type = FormattedInputType.MONEY, value, onChange, onBlur, disabled }) => (
  <Form.Field className='comp-inline-field comp-form-field' inline>
    <label>{label}</label>
    {
      type === FormattedInputType.MONEY ? <MoneyFormattedInput defaultValue={value} onChange={onChange} onBlur={onBlur} disabled={disabled} /> :
        type === FormattedInputType.PERCENT ? <PercentFormattedInput defaultValue={value} onChange={onChange} onBlur={onBlur} disabled={disabled} /> :
          type === FormattedInputType.NUMBER ? <NumberFormattedInput defaultValue={value} onChange={onChange} onBlur={onBlur} disabled={disabled} /> :
            null
    }
  </Form.Field>
)

const RateFormField = ({ value, onChange, isIO, onChangeIO }) => (
  <Form.Field className='comp-inline-field comp-form-field' inline>
    <label>Rate &nbsp; &nbsp;
      <Checkbox label='I/O?' checked={isIO} onChange={onChangeIO} />
    </label>
    <PercentFormattedInput defaultValue={value} onChange={onChange} />
  </Form.Field>
)


let salesCompsData = null
let financingCompsData = null

const Valuation = () => {
  const [loadState, setLoadState] = useState(LoadState.NONE)
  const [value, setValue] = useState('')

  const [target, setTarget] = useState(null)
  const [targetSqft, setTargetSqft] = useState(0)
  const [valuationData, setValuationData] = useState(null)

  const [ltv, setLtv] = useState(75)
  const [minDSCR, setMinDSCR] = useState(1)
  const [buyCondition, setBuyCondition] = useState(PropertyCondition.Fair)
  const [sellCondition, setSellCondition] = useState(PropertyCondition.Fair)
  const [interestRate, setInterestRate] = useState(0)
  const [calculatedRent, setCalculatedRent] = useState(0)

  const [financingCap, setFinancingCap] = useState(0)
  const [insurance, setInsurance] = useState(0)
  const [annualTax, setAnnualTax] = useState(0)

  const [finalLTV, setFinalLTV] = useState(0)
  const [dscr, setDSCR] = useState(0)

  const [isIO, setIsIO] = useState(false)

  const [buckets, setBuckets] = useState(null)
  const [rentalBuckets, setRentalBuckets] = useState(null)
  const [entryPrice, setEntryPrice] = useState(0)
  const [exitPrice, setExitPrice] = useState(0)
  const [profitMarginPercent, setProfitMarginPercent] = useState(25)
  const [maxEntryPrice, setMaxEntryPrice] = useState(0)
  const [minExitPrice, setMinExitPrice] = useState(0)
  const [dscrMaxLoanAmount, setDSCRMaxLoanAmount] = useState(0)

  const [fredIndex, setFredIndex] = useState(null)
  const [constructionCost, setConstructionCost] = useState(0)
  const [constructionCostEstimate, setConstructionCostEstimate] = useState(0)

  const [salesChart, setSalesChart] = useState(null)
  const [loansChart, setLoansChart] = useState(null)

  const [salesTDSChart, setSalesTDSChart] = useState(null)
  const [financingTDSChart, setFinancingTDSChart] = useState(null)

  const [markerData, setMarkerData] = useState(null)

  const insuranceModifier = 1

  useEffect(() => {
    let mounted = true
    if (mounted) {
      getFredPrices().then(prices => {
        const {observations} = prices
        if (observations && observations.length > 0)
          setFredIndex(observations[0].value)
      })
    }
    return () => mounted = false
  }, [])


  useEffect(() => {
    if (target && loadState === LoadState.FINISHED) calculateMinExitAndMaxEntryPrices()
  }, [profitMarginPercent, constructionCostEstimate, entryPrice, exitPrice])

  useEffect(() => {
    if (target && loadState === LoadState.FINISHED) calculateDSCR()
  }, [ltv, minDSCR, interestRate, financingCap, exitPrice, entryPrice, annualTax, insurance, calculatedRent, isIO])

  useEffect(() => {
    if (target && loadState === LoadState.FINISHED) setConstructionCostEstimate(constructionCost * targetSqft)
  }, [constructionCost, targetSqft])

  useEffect(() => {
    if (target) {
      if (targetSqft !== target.finalArea) {
        target.finalArea = targetSqft
        target.pricePerSqft = target.documentAmount / targetSqft

        //financingCompsData.comps[0].finalArea = targetSqft
        ///financingCompsData.comps[0].pricePerSqft = target.documentAmount / targetSqft

        setMarkerData(null)

        // TODO: REPLACE
        //finishSalesComps(calculateExtendedComps(salesCompsData.comps, target, { type: CompTypeOption.DEED }, salesCompsData.neighborhoodAverages))
        //finishFinancingComps(calculateExtendedComps(financingCompsData.comps, target, { type: CompTypeOption.MORTGAGE }, financingCompsData.neighborhoodAverages))
      }
    }
  }, [targetSqft])

  useEffect(() => {
    if (target !== null && !target.label) {
      async function fetchData() {
        var rentalData = {}
        if (Lot.isPluto(target)) {
          rentalData = await makeGetRequest(`${property_api_rents_url}?bbl=${target.propertyId}`)

          //const annualTax = await makeGetRequest(`${property_api_annual_tax_url}?bbl=${target.propertyId}`)
          //setAnnualTax(annualTax.annualTax)
        } else {
          if (target.bedrooms) {
            rentalData.bedrooms = [target.bedrooms]
          }
        }
        setAnnualTax(target.annualTax)

        if (rentalData) {
          const totalBedrooms = rentalData.bedrooms != null && rentalData.bedrooms.length > 0 ? rentalData.bedrooms.reduce((a, b) => a + b) : 0
          const totalRent = rentalData.rents != null && rentalData.rents.length > 0 ? rentalData.rents.reduce((a, b) => a + b) : 0

          rentalData.totalBedrooms = totalBedrooms
          rentalData.totalRent = totalRent

          let seTotalRent = 0
          if (rentalData.bedrooms) {
            rentalData.bedrooms.forEach(rooms => {
              seTotalRent += getRentForRooms(rooms - 1 >= 0 ? rooms - 1 : rooms)
            }) 
          }

          rentalData.seTotalRent = seTotalRent
        }

        await getInterestRates().then(rate => {
          setInterestRate((Math.ceil((rate + 3.25) * 8)) / 8)
        })
        
        setValuationData(valuationData => (
          {
            ...valuationData,
            rentalData,
            getFinancing: true
          }
        ))
      }
      fetchData()
    }
  }, [target])

  function calculateExitCosts(exit, lot) {
    if (Lot.isPluto(lot)) {
      return (exit * SALES_COST_PERCENTAGE) + (exit * BROKER_FEES_PERCENTAGE)
    }
    return (exit * .06); // combined fees
  }

  function calculateEntryCosts(entry, lot) {
    if (lot && Lot.isPluto(lot)) {
      return (entry * PURCHASE_COST_PERCENTAGE) + (entry * SOFT_COST_PERCENTAGE)
    }
    return (entry * .05);// combined fees
  }

  function totalInvestment(entry, exit, construction, lot) {
    return roundToNearest(entry) + roundToNearest(construction) + roundToNearest(calculateExitCosts(exit, lot)) + roundToNearest(calculateEntryCosts(entry, lot))
  }

  function goalSeekEntryPrice({
    targetProfitMargin,
    exitPrice,
    constructionCostEstimate,
    tolerance = 0.0001,
    maxIterations = 100
  }) {
    let low = 0;
    let high = exitPrice;
    let iteration = 0;
  
    while (iteration < maxIterations) {
      let entryPrice = (low + high) / 2;

      let closingCosts = calculateEntryCosts(entryPrice, target)
  
      let constructionCosts = constructionCostEstimate; // if fixed, otherwise use exitPrice * 0.0625
      let exitCosts = calculateExitCosts(exitPrice, target)
  
      let totalCosts = entryPrice + closingCosts + constructionCosts + exitCosts;
      let netProfit = exitPrice - exitCosts - constructionCosts - closingCosts - entryPrice;
  
      let calculatedMargin = netProfit / totalCosts;
  
      let error = calculatedMargin - targetProfitMargin;
  
      if (Math.abs(error) < tolerance) {
        return entryPrice;
      }
  
      if (error > 0) {
        // We're making too much profit; entryPrice can go higher
        low = entryPrice;
      } else {
        // Not enough profit; entryPrice needs to be lower
        high = entryPrice;
      }
  
      iteration++;
    }
  
    return 0;
  }

  const calculateMinExitAndMaxEntryPrices = () => {
    let targetProfitMargin = (profitMarginPercent / 100)

    //const min = ((entryPrice + constructionCosts) * (1 + profitMargin))
    //let max = (((exitPrice / (1 + profitMargin)) - constructionCosts) - existCosts)

    //const expenses = max * CLOSING_COST_PERCENTAGE
    //max = max - expenses

    //const targetEntry = exitPrice

    const calculatedEntryPrice = goalSeekEntryPrice({
      targetProfitMargin,
      exitPrice,
      constructionCostEstimate
    });

    setMaxEntryPrice(calculatedEntryPrice)
    //setMinExitPrice(min)
  }

  const getCalcPrice = (calc, isRent = false) => {
    let val = BucketCalc.selectedPriceToUse(calc)
    if (isNaN(val)) return 0
    if (isRent) return val
    return roundToFloorByNearest(val, 5000) || 0
}

  const getRentalCalc = () => {
    const { lowBucket, middleBucket, highBucket } = rentalBuckets || {}
    let calc = null
    switch (sellCondition) {
        case PropertyCondition.Excellent:
            calc = highBucket
            break
        case PropertyCondition.Fair:
            calc = middleBucket
            break
        case PropertyCondition.Poor:
            calc = lowBucket
            break
    }

    return calc
}

const getConfidenceScore = (calc) => {
  return calc?.likenessAverage ?? 0
}

const rentToUse = () => {
    let rent = getCalcPrice(getRentalCalc(), true)
    let rentConfidence = getConfidenceScore(getRentalCalc()) / 100

    let finalRent = roundToFloorByNearest((rent * rentConfidence) + (calculatedRent * (1 - rentConfidence)), 25)
    return finalRent
}

  const calculateTotalRent = () => {
    const { rentalData } = valuationData
    let salesPrice = exitPrice

    let units = Lot.isPluto(target) ? target.unitCode : target.unitstotal

    let tmpTotalRent = getRentalRate(salesPrice, units)

    const { seTotalRent } = rentalData || {}
    const rentToUse = seTotalRent >= tmpTotalRent ? seTotalRent : tmpTotalRent

    setCalculatedRent(roundToNearest(rentToUse))
  }

  const calculateConstructionCosts = () => {
    const modifier = Math.min(exitPrice/2000000, 1)
    //const fredHigh = (valuationData?.exitPSF * .25)

    const costMultipliers = {
      [PropertyCondition.Poor]: {
        [PropertyCondition.Fair]: 0.375,
        [PropertyCondition.Excellent]: 0.625,
      },
      [PropertyCondition.Fair]: {
        [PropertyCondition.Excellent]: 0.375,
      },
      [PropertyCondition.Excellent]: {
        [PropertyCondition.Excellent]: 0,
      },
    };

    let tmpConstructionCosts = 0;

    const multiplier = costMultipliers[buyCondition]?.[sellCondition] ?? 0;
    tmpConstructionCosts = (fredIndex * multiplier) * modifier;

    setConstructionCost(roundToNearest(tmpConstructionCosts))

    let estimates = tmpConstructionCosts * targetSqft

    setValuationData(valuationData => ({
      ...valuationData,
      constructionCostEstimate: estimates,
      profitAtExit: (valuationData.exitPrice && ((valuationData.exitPrice - valuationData.entryPrice - estimates) / (valuationData.entryPrice + estimates)) * 100)
    }))
  }

  const calculateDSCR = () => {
    const { middleCalc } = valuationData

    const payment = isIO ? ((financingCap * (interestRate / 100)) / 12) : calculatePayment(financingCap, (interestRate / 100))
    const piti = payment + (annualTax / 12) + (insurance / 12)

    let ltvValue = 0
    let tmpPrice = 0
    switch (sellCondition) {
      case PropertyCondition.Poor:
        // Take Low Bucket Price * LTV
        tmpPrice = entryPrice
        break
      case PropertyCondition.Fair:
        // Take Middle Bucket Price * LTV
        tmpPrice = BucketCalc.selectedPriceToUse(middleCalc)
        break
      case PropertyCondition.Excellent:
        // Take High Bucket Price * LTV
        tmpPrice = exitPrice
        break
      default:
        break
    }
    ltvValue = tmpPrice * (ltv / 100)

    const dscr = calculatedRent / piti
    // console.log('PAYMENT ', payment)
    // console.log('PITI ', piti)
    // console.log('TOTAL RENT ', totalRent)
    // console.log('DSCR ', dscr)

    let dscrMaxLoanAmount = financingCap
    // console.log('MAX LOAN AMOUNT ', dscrMaxLoanAmount)
    if (dscr < minDSCR) {
      // console.log('DSCR LOWER THAN MIN DSCR')
      const pmt = (calculatedRent / minDSCR) - (annualTax / 12) - (insurance / 12)
      dscrMaxLoanAmount = isIO ? ((pmt * 12) / (interestRate / 100)) : calculatePmt2Loan(pmt, (interestRate / 100))
      // console.log('NEW MAX LOAN AMOUNT ', dscrMaxLoanAmount)
    }

    if (dscrMaxLoanAmount > ltvValue) {
      // console.log('MAX LOAN AMOUNT GREATER THAN LTV VALUE')
      dscrMaxLoanAmount = ltvValue
      // console.log('NEW MAX LOAN AMOUNT ', dscrMaxLoanAmount)
    }

    const dscrPayment = isIO ? ((dscrMaxLoanAmount * (interestRate / 100)) / 12) : calculatePayment(dscrMaxLoanAmount, (interestRate / 100))
    const dscrPiti = dscrPayment + (annualTax / 12) + (insurance / 12)
    // console.log('DSCR PAYMENT', dscrPayment)
    // console.log('DSCR PITI', dscrPiti)

    setFinalLTV((dscrMaxLoanAmount / tmpPrice) * 100)
    setDSCR(calculatedRent / dscrPiti)
    setDSCRMaxLoanAmount(dscrMaxLoanAmount)

    setValuationData(valuationData => ({
      ...valuationData,
      piti,
      payment
    }))
  }

  const onClickBack = () => {
    setValue('')
    setTarget(null)
    setTargetSqft(0)
    setConstructionCost(0)
    setValuationData(null)

    setSalesChart(null)
    setLoansChart(null)
    setSalesTDSChart(null)
    setFinancingTDSChart(null)

    setFinancingCap(0)
    setCalculatedRent(0)
    setInsurance(0)
    setAnnualTax(0)

    setFinalLTV(0)
    setDSCR(0)

    setIsIO(false)

    setEntryPrice(0)
    setExitPrice(0)
    setMaxEntryPrice(0)
    setMinExitPrice(0)
    setDSCRMaxLoanAmount(0)

    setBuckets(null)
    setMarkerData(null)

    setLoadState(LoadState.NONE)
    salesCompsData = null
    financingCompsData = null
  }

  const handleSearchChange = async (written, label, propertyData) => {
    await setPropertyToRun(propertyData)
};

  const setPropertyToRun = async (result) => {
          onClickBack()
  
          var lot_url = property_api_get_lot_url;
          var url = `${lot_url}?propertyId=${result.propertyId}`;
  
          var subject = await makeGetRequest(url)
          setTarget(subject)
          setLoadState(LoadState.SALES)
      }

  useEffect(() => {
    switch (loadState) {
        case LoadState.SALES:
          runAllComps(target,
                {
                    type: CompTypeOption.DEED,
                    version: VERSION,
                    allVersions: ALL_VERSIONS
                }, (compResults, canceled) => {
                    if (!canceled) {
                        finishSalesComps(compResults)
                    }
                })
            break
        case LoadState.FINANCING:
          runAllComps(target,
                {
                    type: CompTypeOption.MORTGAGE,
                    version: VERSION,
                    allVersions: ALL_VERSIONS
                },
                (compResults, canceled) => {
                    if (!canceled) {
                        finishFinancingComps(compResults)
                    }
                })
            break
        case LoadState.RENTALS:
          runAllComps(target,
                {
                    type: CompTypeOption.RENT,
                    version: VERSION,
                    allVersions: ALL_VERSIONS,
                    bedrooms: target.bedrooms
                },
                (compResults, canceled) => {
                    if (!canceled) {
                       finishRentalComps(compResults)
                    }
                })
            break
        case LoadState.FINISHING:
            {
              calculateTotalRent()
              calculateConstructionCosts()
              calculateMinExitAndMaxEntryPrices()
      
              setLoadState(LoadState.FINISHED)
            }
            break
        default:
          break
    }
}, [loadState])

function getEntryPrice () {
  if (!buckets) return 0
  const {finalBuckets, composyteBuckets} = buckets || {}
  var bucket = null
  switch(buyCondition) {
    case PropertyCondition.Poor:
      bucket = finalBuckets?.lowBucket ?? composyteBuckets.find(b => b.title === "Lowest")
      break;
    case PropertyCondition.Fair:
      bucket = finalBuckets?.middleBucket ?? finalBuckets?.lowBucket
      break;
    case PropertyCondition.Excellent:
      bucket = composyteBuckets.find(b => b.title === "Highest") ?? finalBuckets?.highBucket
      break;
  }

  let entry = bucket && BucketCalc.selectedPriceToUse(bucket)
  return entry
 }

 function getExitPrice() {
  if (!buckets) return 0
  const {finalBuckets, composyteBuckets} = buckets || {}
  var bucket = null
  switch(sellCondition) {
    case PropertyCondition.Poor:
      bucket = finalBuckets?.lowBucket ?? composyteBuckets.find(b => b.title === "Lowest")
      break;
    case PropertyCondition.Fair:
      bucket = finalBuckets?.middleBucket ?? finalBuckets?.lowBucket
      break;
    case PropertyCondition.Excellent:
      bucket = composyteBuckets.find(b => b.title === "Highest") ?? finalBuckets?.highBucket
      break;
  }

  let exit = bucket && BucketCalc.selectedPriceToUse(bucket)
  return exit
 }

 useEffect(() => {
    if (target && loadState === LoadState.FINISHED) {
      calculateTotalRent()
      calculateConstructionCosts()
    }
 }, [entryPrice, exitPrice])

 useEffect(() => {
    if (buckets) {
      setEntryPrice(getEntryPrice())
      setExitPrice(getExitPrice())
    }
 }, [buckets, buyCondition, sellCondition])

  const finishSalesComps = (compsData) => {
    if (compsData) {
      salesCompsData = compsData

      const {stats, comps, buckets} = compsData || {}
      let foundTarget = comps.filter(_ => _.propertyId.toString() === target.propertyId.toString())[0]
      const {valuationData: data, chartData} = stats || {}
      const {finalBuckets} = buckets || {}
      setBuckets(buckets)

      setValuationData(valuationData => ({
        ...valuationData,
        //exitPSF: highest && BucketCalc.selectedPsfToUse(highest),
        middleCalc: finalBuckets?.middleBucket,
        totalDistance: data?.totalDistance
      }))

      setTarget(foundTarget)
      setValue(Lot.fullAddress(target))

      setTargetSqft(foundTarget.finalArea)
      setInsurance(foundTarget.finalArea * insuranceModifier)

      setMarkerData(comps.filter(_ => _.bucket && _.bucket.length > 0 && _.bucket !== CompBucketOption.SUBJECT && _.bucket !== CompBucketOption.REMOVED))

      if (Lot.isPluto(foundTarget)) {
        fetchSales(foundTarget, finalBuckets)
      }
      setSalesTDSChart(setupLTDSChart(comps, chartData))

      setLoadState(LoadState.FINANCING)
    }
  }

  const finishFinancingComps = (compsData) => {
    if (compsData) {
      const {stats, comps, buckets} = compsData || {}
      let foundTarget = comps.filter(_ => _.propertyId.toString() === target.propertyId.toString())[0]

      const {finalBuckets} = buckets || {}
      const {valuationData: data, chartData} = stats || {}
      setValuationData(valuationData => ({
        ...valuationData,
        financingMiddleCalc: finalBuckets?.middleBucket,
        getFinancing: false
      }))

      setFinancingCap(finalBuckets && finalBuckets?.middleBucket && BucketCalc.selectedPriceToUse(finalBuckets?.middleBucket))

      if (Lot.isPluto(foundTarget)) {
        fetchSales(foundTarget, finalBuckets, false)
      }
      setFinancingTDSChart(setupLTDSChart(comps, chartData))

      setLoadState(LoadState.RENTALS)
    }
  }

  const finishRentalComps = (compsData) => {
    if (compsData) {
      const {buckets} = compsData || {}

      const {finalBuckets} = buckets || {}
      setValuationData(valuationData => ({
        ...valuationData,
        finishCalculating: true
      }))

      setRentalBuckets(finalBuckets)
      setLoadState(LoadState.FINISHING)
    }
  }

  const setupLTDSChart = (comps, stdInfo) => {
    let data = comps.map(_ => {
      let color = ''
      if (_.finalBuckets && _.finalBuckets.length > 0) {
        color = bucketColor(_.finalBuckets[0])
      } else {
        color = '#ececec'
      }
      return getTDSChartData(_, color)
    })

    data.sort((a, b) => a.x > b.x ? 1 : -1)

    let movingAverage = getChartMovingAverage(data, 0, 100, 10)

    //console.log(movingAverage)
    return { data: data, stdInfo, movingAverage }
  }

  const fetchSales = async (target, calc, isSales = true) => {
    const { neighborhood, borocode, finalArea, residentialUnits } = target
    console.log(target)
    //const { lowBucketCalc, middleLowBucketCalc, middleBucketCalc, middleHighBucketCalc, highBucketCalc } = calc
    const { lowBucket, middleBucket, highBucket } = calc

    const url = isSales ? property_api_get_neighborhood_sales_url : property_api_get_neighborhood_loans_url

    const result = await makeGetRequest(`${url}?neighborhood=${neighborhood}&borocode=${borocode}`)
    if (result) {
      const sales = JSON.parse(result.chart)
      const saleData = sales.map(_ => _.sale)

      let years = saleData.map(property("years")).reduce(flatten, []).filter(onlyUnique)

      years.sort()

      let saleTrends = []
      let unitSaleTrends = []
      let movingAverage = []
      let unitMovingAverage = []

      let t = { ...getSalesForNeighborhood(saleData, neighborhood, borocode) }
      if (t && t.data) {
        years.reverse().forEach(year => {
          let sale = {}
          let yearlyData = t.data.filter(_ => _.year === year /*&& _.units === target.unitsres*/)
          sale.data = yearlyData.sort((a, b) => (a.y > b.y) ? -1 : 1)
          sale.neighborhood = t.neighborhood
          sale.year = year

          saleTrends.push(sale)

          let unitSale = {}
          let unitYearlyData = t.data.filter(_ => _.year === year && _.units === residentialUnits)
          unitSale.data = unitYearlyData.sort((a, b) => (a.y > b.y) ? -1 : 1)
          unitSale.neighborhood = t.neighborhood
          unitSale.year = year

          unitSaleTrends.push(unitSale)
        })
      }

      let lowX = finalArea * .9
      let highX = finalArea * 1.1

      let weightedAverages = []

      if (!isEmpty(lowBucket)) {
        weightedAverages.push(getChartAvgSaleLine(lowX, highX, BucketCalc.selectedPsfToUse(lowBucket), bucketColor(CompBucketOption.LOW)))
      }

      // if (!isEmpty(middleLowBucketCalc)) {
      //   weightedAverages.push(getChartAvgSaleLine(lowX, highX, middleLowBucketCalc?.avgPSF, bucketColor(CompBucketOption.MIDDLELOW)))
      // }

      if (!isEmpty(middleBucket)) {
        weightedAverages.push(getChartAvgSaleLine(lowX, highX, BucketCalc.selectedPsfToUse(middleBucket), bucketColor(CompBucketOption.MIDDLE)))
      }

      // if (!isEmpty(middleHighBucketCalc)) {
      //   weightedAverages.push(getChartAvgSaleLine(lowX, highX, middleHighBucketCalc?.avgPSF, bucketColor(CompBucketOption.MIDDLEHIGH)))
      // }

      if (!isEmpty(highBucket)) {
        weightedAverages.push(getChartAvgSaleLine(lowX, highX, BucketCalc.selectedPsfToUse(highBucket), bucketColor(CompBucketOption.HIGH)))
      }


      if (saleTrends.length > 0) {
        let allData = saleTrends.map(property("data")).reduce(flatten, []).sort((a, b) => (a.x > b.x) ? 1 : -1)

        movingAverage = getChartMovingAverage(allData)

        let unitData = allData.filter(_ => _.units === residentialUnits)

        unitMovingAverage = getChartMovingAverage(unitData)
      }

      if (isSales) {
        setSalesChart({ saleTrends, unitSaleTrends, movingAverage, unitMovingAverage, weightedAverages: weightedAverages })
      } else {
        setLoansChart({ saleTrends, unitSaleTrends, movingAverage, unitMovingAverage, weightedAverages: weightedAverages })
      }
    }
  }

  return (
    <div className='resp-container'>
      <Segment basic style={{ minHeight: '100vh', background: '#f8f8f8', padding: '4em 2em 2em' }}>
        <Form size='small'>
          <Form.Group>
            <Form.Field className='comp-inline-field' inline>
              <label>Subject Property</label>
              <SearchInput 
                className="fluid" 
                name=''
                label=''
                icon='home'
                value={value}
                placeholder='Enter property address'
                onChange={handleSearchChange}
                  />
            </Form.Field>
            <Form.Select
              options={propertyConditionOptions}
              placeholder='Select Buy Condition'
              value={buyCondition}
              onChange={(e, { value }) => setBuyCondition(value)}
            />
            <Form.Select
              options={propertyConditionOptions}
              placeholder='Select Sell Condition'
              value={sellCondition}
              onChange={(e, { value }) => setSellCondition(value)}
            />
            <Form.Field><Button icon='x' onClick={onClickBack}></Button></Form.Field>
          </Form.Group>
        </Form>
        <Divider />
        <Grid columns={2}>
          <Grid.Row stretched>
            <Grid.Column width={3}>
              <Segment.Group>
                <ValueContainer top='DSCR' label='Max Loan Amount' value={roundToNearest(dscrMaxLoanAmount)} />
                <Segment.Group className='values-container' horizontal style={{ background: 'white' }}>
                  <ValueContainer label='LTV' value={finalLTV} type={FormattedInputType.PERCENT} />
                  <ValueContainer label='DSCR' value={dscr} type={FormattedInputType.NONE} />
                </Segment.Group>
              </Segment.Group>
            </Grid.Column>
            <Grid.Column width={13}>
                <Segment.Group className='flip-flip-values-container' horizontal style={{ background: 'white' }}>
                  {/* <ValueContainer top=' &nbsp; ' label='Entry Price' value={roundToNearest(valuationData?.entryPrice ?? 0)} /> */}
                  <ValueContainer top='Max Purchase Price' label='' value={roundToNearest(maxEntryPrice)} color={maxEntryPrice < entryPrice ? 'red' : ''} />
                  <ValueContainer top='Purchase Expenses' label='' value={roundToNearest(calculateEntryCosts(maxEntryPrice ?? 0, target))} />
                  {/* <ValueContainer top=' &nbsp; ' label='Entry Costs' value={roundToNearest(entryCosts(maxEntryPrice, target))} /> */}
                  <ValueContainer top='Const. Cost Estimate' label='' value={roundToNearest(constructionCostEstimate ?? 0)} />
                  {/* <ValueContainer top=' &nbsp; ' label='Const. Budget' value={roundToNearest(constructionCostEstimate)} /> */}
                  <ValueContainer top='Sale Expenses' label='' value={roundToNearest(calculateExitCosts(exitPrice ?? 0, target))} />
                  {/* <ValueContainer top='F & F Calculated' label='Exit Costs' value={roundToNearest(exitCosts(exitPrice, target))} /> */}
                  {/* <ValueContainer top=' &nbsp; ' label='Total Investment' value={roundToNearest(target ? totalInvestment(valuationData?.entryPrice, valuationData?.exitPrice, valuationData?.constructionCostEstimate) : 0)} /> */}
                  <ValueContainer top='Total Cost' label='' value={roundToNearest(target ? totalInvestment(maxEntryPrice, exitPrice, constructionCostEstimate, target) : 0)} />
                  <ValueContainer top='Sales Price' label='' value={roundToNearest(exitPrice ?? 0)} />
                  {/* <ValueContainer top=' &nbsp; ' label='Min Exit Price' value={roundToNearest(minExitPrice)} color={minExitPrice > exitPrice ? 'red' : ''} /> */}
                  <ValueContainer top='Potential Profit' label='' value={roundToNearest(exitPrice ?? 0) - roundToNearest(target ? totalInvestment(maxEntryPrice, exitPrice, constructionCostEstimate, target) : 0)}  />
                  <ValueContainer top='Profit Margin' label='' value={target && profitMarginPercent ? profitMarginPercent : 0} type={FormattedInputType.PERCENT} />
                  {/* <ValueContainer top=' &nbsp; ' label='Profit at Exit' value={valuationData?.profitAtExit ?? 0} type={FormattedInputType.PERCENT} color={valuationData?.profitAtExit < profitMarginPercent ? 'red' : ''} /> */}
                </Segment.Group>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Grid textAlign='center' columns='equal'>
          <Grid.Row>
            <Grid.Column>
              <Form>
                <CompFormField label='Entry Price' value={entryPrice} onChange={(e, value) => setEntryPrice(Number(value))} />
                <CompFormField label='Exit Price' value={exitPrice} onChange={(e, value) => setExitPrice(Number(value))} />
                <CompFormField label='Financing Cap' value={financingCap} onChange={(e, value) => setFinancingCap(Number(value))} disabled={true} />
                <CompFormField label='Rents/Mo' value={rentToUse()} onChange={(e, value) => setCalculatedRent(Number(value))} />
                <CompFormField label='Property Sqft' type={FormattedInputType.NUMBER} value={targetSqft} onBlur={(e, value) => setTargetSqft(Number(value))} />
                <CompFormField label='Const. Cost' value={constructionCost} onChange={(e, value) => setConstructionCost(Number(value))} />
              </Form>
            </Grid.Column>
            <Grid.Column>
              <Form>
                <CompFormField label='LTV' type={FormattedInputType.PERCENT} value={ltv} onChange={(e, value) => setLtv(Number(value))} />
                <RateFormField value={interestRate} onChange={(e, value) => setInterestRate(Number(value))} isIO={isIO} onChangeIO={(e, target) => setIsIO(target.checked)} />
                <CompFormField label='Min DSCR' type={FormattedInputType.NUMBER} value={minDSCR} onChange={(e, value) => setMinDSCR(Number(value))} />
                <CompFormField label='Profit Margin %' type={FormattedInputType.PERCENT} value={profitMarginPercent} onChange={(e, value) => setProfitMarginPercent(Number(value))} />
                <CompFormField label='Insurance/Yr' value={insurance} onChange={(e, value) => setInsurance(Number(value))} />
                <CompFormField label='Taxes/Yr' value={annualTax} onChange={(e, value) => setAnnualTax(Number(value))} />
              </Form>
            </Grid.Column>
            <Grid.Column>
              <MapboxMap
                className='valuation-map-container'
                target={target}
                markerData={markerData}
                totalDistance={valuationData?.totalDistance || 1}
                popupPosition='left'
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Divider />
        <Segment.Group style={{ height: '50vh', flex: 'auto', margin: 0 }} stacked horizontal>
          {salesTDSChart && <Segment basic className='popup-tds-chart-container'>
            <LTDSChart
              chartData={salesTDSChart}
              cWidth={600}
            />
          </Segment>

          }

          {salesChart && <Segment basic className='popup-sale-chart-container'>
            <SaleChart
              chartData={salesChart.unitSaleTrends}
              //movingAverage={salesChart.movingAverage} 
              movingAverage={null}
              unitMovingAverage={salesChart.unitMovingAverage}
              weightedAverages={salesChart.weightedAverages}
              scatterSize={1}
              showLegend={false}
              brushArea={target.finalArea}
              sameColor={true} />
          </Segment>
          }
        </Segment.Group>
        <Segment.Group style={{ height: '50vh', flex: 'auto' }} stacked horizontal>

          {financingTDSChart && <Segment basic className='popup-tds-chart-container'>
            <LTDSChart
              chartData={financingTDSChart}
              cWidth={600}
            />
          </Segment>

          }

          {loansChart && <Segment basic className='popup-sale-chart-container'>
            <SaleChart
              chartData={loansChart.unitSaleTrends}
              //movingAverage={salesChart.movingAverage} 
              movingAverage={null}
              unitMovingAverage={loansChart.unitMovingAverage}
              weightedAverages={loansChart.weightedAverages}
              scatterSize={1}
              showLegend={false}
              brushArea={target.finalArea}
              sameColor={true} />
          </Segment>
          }
        </Segment.Group>
        <Dimmer page active={loadState > LoadState.NONE && loadState < LoadState.FINISHED}>
          <Loader />
        </Dimmer>
      </Segment>
    </div>
  )
}

export default Valuation


