import React, { useState, useEffect, useRef } from "react";
import { Loader, Menu } from "semantic-ui-react";
import { classNames } from "../../utility/Helper";
import { property_api_urbyn_search_address_url } from "../../utility/Config"
import { getUser } from "../../utility/Auth";
import SearchSpinner from "./SearchSpinner";

// Function to get current user location
function getCurrentPosition() {
  return new Promise((resolve, reject) => {
    if (!navigator.geolocation) {
      reject(new Error("Geolocation is not supported by this browser."));
      return;
    }
    
    navigator.geolocation.getCurrentPosition(
      (position) => {
        resolve({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude
        });
      },
      (error) => {
        reject(error);
      },
      { enableHighAccuracy: true, timeout: 5000, maximumAge: 0 }
    );
  });
}

// Function to fetch address suggestions from your API with coordinates
export async function getPropertySuggestions(search, includeCoordinates = true, signal) {
  if (!search || search.trim() === '') return;

  // Ensure at least one word is present and it is not just a number
  const words = search.trim().split(/\s+/);
  if (words.length < 1 || words.every(word => /^\d+$/.test(word))) {
    console.warn("Query ignored: single-word numeric input");
    return; // Ignore queries like "2" or "20"
  }
  
  try {
    let apiUrl = `${property_api_urbyn_search_address_url}?query=${encodeURIComponent(search)}`;
    
    // Add coordinates to the request if enabled
    if (includeCoordinates) {
      try {
        const position = await getCurrentPosition();
        apiUrl += `&latitude=${position.latitude}&longitude=${position.longitude}`;
      } catch (locationError) {
        // If getting location fails, continue without coordinates
        console.warn("Could not get user location:", locationError.message);
      }
    }
    
    // Get authentication token
    const token = (await getUser()).access_token;
    
    // Create request options with auth headers and attach signal for aborting
    const options = {
      method: "GET",
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        'Connection': 'Keep-Alive',
        'Authorization': 'Bearer ' + token
      },
      signal // Attach abort signal to the request
    };
    
    const response = await fetch(apiUrl, options);
    
    if (!response.ok) {
      throw new Error(`API request failed with status ${response.status}`);
    }
    
    return await response.json();
  } catch (error) {
    if (error.name === "AbortError") {
      //console.log("Request aborted:", search);
      return; // Gracefully handle aborted request
    }
    console.error("Error fetching address suggestions:", error);
  }
}

function SearchInput(props) {
  const [query, setQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [showResults, setShowResults] = useState(false);
  const [highlightedIndex, setHighlightedIndex] = useState(-1); // Track highlighted result

  const { onChange, value, icon = 'search', name, placeholder, disabled } = props;
  const inputRef = useRef(null);
  const resultsRef = useRef(null);
  const abortControllerRef = useRef(null);
  const requestIdRef = useRef(0); // Track request sequence to avoid race conditions
  const [isFetching, setIsFetching] = useState(false);

  // Handle clicks outside the results menu to close it
  useEffect(() => {
    function handleClickOutside(event) {
      if (
        resultsRef.current && 
        typeof resultsRef.current.contains === "function" && 
        !resultsRef.current.contains(event.target) &&
        inputRef.current && 
        typeof inputRef.current.contains === "function" && 
        !inputRef.current.contains(event.target)
      ) {
        setShowResults(false);
      }
    }
    
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  
  useEffect(() => {
    const trimmedQuery = query.trim();
    
    // Skip search if query is empty
    if (!trimmedQuery) {
      setSearchResults([]);
      setShowResults(false);
      return;
    }

    // Check if query has at least one valid word (2+ chars, not just numbers)
    const words = trimmedQuery.split(/\s+/).filter(word => word.length > 0);
    const hasValidWord = words.some(word => word.length >= 2 && !/^\d+$/.test(word));
    
    // If no valid words and not a selected property, don't search
    if (!hasValidWord && !selectedProperty) {
      setSearchResults([]);
      setShowResults(false);
      return;
    }
    
    // If user just selected a property, don't trigger another search
    if (selectedProperty && trimmedQuery === selectedProperty.label) {
      return;
    }

    // Function to perform the actual search
    const performSearch = async () => {
      // Abort previous request if exists
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
      
      // Create new abort controller
      abortControllerRef.current = new AbortController();
      const signal = abortControllerRef.current.signal;
      
      // Generate unique ID for this request
      const currentRequestId = ++requestIdRef.current;
      
      setIsFetching(true);
      
      try {
        const results = await getPropertySuggestions(
          trimmedQuery, 
          props.includeCoordinates !== false, 
          signal
        );
        
        // Only update state if this is the most recent request
        if (currentRequestId === requestIdRef.current && !signal.aborted) {
          setSearchResults(results || []);
          setShowResults(results && results.length > 0);
        }
      } catch (error) {
        if (error.name !== "AbortError") {
          console.error("Search failed:", error);
        }
      } finally {
        if (currentRequestId === requestIdRef.current) {
          setIsFetching(false);
        }
      }
    };

    // Determine if we should search immediately or use debounce
    const isPaste = query.length - trimmedQuery.length > 5; // Heuristic for paste detection
    const isInitialSearch = searchResults.length === 0;
    
    if (isPaste || isInitialSearch) {
      // Immediate search for pastes or initial searches
      performSearch();
    } else {
      // Debounced search for typing
      const debounceTimer = setTimeout(performSearch, 300);
      return () => clearTimeout(debounceTimer);
    }
  }, [query, props.includeCoordinates, selectedProperty]);

  useEffect(() => {
    if (value && value !== query) {
      setQuery(value);
      setSelectedProperty({ label: value }); // Prevent triggering search
    }
  }, [value]);

  const handleInputChange = (event) => {
    const newQuery = event.target.value;
    setQuery(newQuery);
    
    // Clear selected property when input changes
    if (selectedProperty && selectedProperty.label !== newQuery) {
      setSelectedProperty(null);
    }
    
    setHighlightedIndex(-1);
  };
  
  // Handle result selection
  const handleResultSelect = (property) => {
    setSelectedProperty(property);
    setQuery(property.label);
    setShowResults(false);
    setHighlightedIndex(-1);
    
    // Call the parent component's onChange handler
    if (onChange) {
      onChange(query, property.label, property);
    }
  };

  // Handle keyboard navigation
  const handleKeyDown = (event) => {
    if (!showResults) return;
    
    switch (event.key) {
      case 'ArrowDown':
        event.preventDefault();
        setHighlightedIndex(prev => 
          prev < searchResults.length - 1 ? prev + 1 : prev
        );
        break;
      case 'ArrowUp':
        event.preventDefault();
        setHighlightedIndex(prev => (prev > 0 ? prev - 1 : 0));
        break;
      case 'Enter':
        if (highlightedIndex >= 0 && highlightedIndex < searchResults.length) {
          handleResultSelect(searchResults[highlightedIndex]);
        }
        break;
      case 'Escape':
        setShowResults(false);
        break;
      default:
        break;
    }
  };
  
  return (
    <div
      className={classNames(props.className, "ui transparent input ui small search info-search search-input-container")}
      style={{ ...props.style, position: 'relative' }}
    >
      <div className="ui right icon input">
        <input
          className="prompt"
          disabled={disabled}
          name={name}
          ref={inputRef}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          type="text"
          onFocus={() => searchResults.length > 0 && setShowResults(true)}
          placeholder={placeholder}
          value={query}
          defaultValue={value}
          size="small"
          transparent
        />        
        <span className="icon-wrapper">
          {isFetching ? 
          <SearchSpinner size="14px" color="#4183c4" />  : 
          <i aria-hidden="true" className={`${icon} icon`}></i>
          }
        </span>
      </div>
      
      {showResults && (
        <Menu 
          vertical 
          ref={resultsRef}
          style={{ 
            position: 'absolute', 
            top: '100%', 
            left: 0, 
            right: 0, 
            zIndex: 1000,
            maxHeight: '300px',
            width: '100%',
            overflowY: 'auto',
            marginTop: '5px',
            boxShadow: '0 2px 10px rgba(0,0,0,0.1)'
          }}
        >
          {searchResults.map((property, index) => (
            <Menu.Item
              key={`${property.propertyId}`}
              onClick={() => handleResultSelect(property)}
              style={{ 
                cursor: 'pointer',
                padding: '10px',
                textAlign: 'left',
                backgroundColor: highlightedIndex === index ? "#ddd" : "white", // Highlight selection
                borderBottom: index < searchResults.length - 1 ? '1px solid #f0f0f0' : 'none'
              }}
            >
              {property.label}
            </Menu.Item>
          ))}
        </Menu>
      )}
    </div>
  );
}

export default SearchInput;