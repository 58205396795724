import React, { useEffect, useState } from 'react'
//import { useNavigate } from 'react-router-dom';
import moment from 'moment'

import Lot from "../Tax-Lot.js"
import { formatNumber } from '../utility/Helper.js'
import {
  Grid,
  Header,
  Segment,
  Sidebar,
  Button,
  Placeholder,
  TextArea,
  Form,
  Divider,
  Label,
  Modal,
  Input
} from 'semantic-ui-react'

import PerfectScrollbar from 'react-perfect-scrollbar'

import { isUserAdmin } from '../utility/Auth'
import GoogleMap from './maps/GoogleMap'
import { ContainedAccordian } from './extras/ContainedAccordian.js'

import { LotBadgesPanel, LotDocumentInformationPanel, LotFooterInformationPanel, LotInformationPanel, LotMiscInformationPanel, LotOwnerInformationPanel, LotStatsPanel, CityDataRow } from './lots/LotInformationPanel.jsx'
import { PlaceholderStatSet, PlaceholderTableLine } from './extras/Misc.js'
import { max } from '../utility/MathHelper.js'

export let AllVersions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]

function concentrationCount(totals) {
  if (predominantType(totals) !== 'residential') return false
  const { unitCounts } = totals[0]
  const totalRes = unitCounts[1] + unitCounts[2] + unitCounts[3] + unitCounts[4]
  const totalFivePlus = unitCounts[5]

  if (totalRes > totalFivePlus) {
    return '1-4'
  } else {
    return '5+'
  }
}

function predominantType(totals) {
  const { unitCounts } = totals[0]
  const totalVacant = unitCounts[0];
  const totalRes = unitCounts[1] + unitCounts[2] + unitCounts[3] + unitCounts[4] + unitCounts[5]
  const totalCom = unitCounts[6]
  const totalMixed = unitCounts[7] + unitCounts[8]
  var values = [totalRes, totalCom, totalMixed, totalVacant]

  var highest = max(values)

  switch (highest) {
    case totalRes:
      return 'residential'
    case totalCom:
      return 'commercial'
    case totalMixed:
      return 'mixed use'
    case totalVacant:
      return 'vacant'
  }
}


const LotNarrative = ({ narrative }) => (
  <Modal.Content>
    <p>
     {narrative}
    </p>
  </Modal.Content>
)

const LotInformationSection = ({ selectedLot, lotUpdateHandlers, handleWideStreetChange }) => (
  <section>
    <LotOwnerInformationPanel lot={selectedLot} />
    <Divider />
    <LotDocumentInformationPanel lot={selectedLot} />
    <Divider />
    <LotInformationPanel lot={selectedLot} updateHandlers={lotUpdateHandlers} handleWideStreetChange={handleWideStreetChange} />
    <Divider />
    <LotFooterInformationPanel lot={selectedLot} />
  </section>
)

export const FullLotInformation = ({ isMobile = false, selectedLot, 
  combinedCompsActive, compsActive, handleCombinedComps, handleCombinedForcedComps, handleComps, handleAllComps, handleAll2Comps,
  handleComps2, handleComps3, handleComps4, handleComps5, handleComps6, handleComps6C, handleComps6D, handleComps7,
  comps2Active, comps3Active, comps4Active, comps5Active, comps6Active, comps6CActive, comps6DActive, comps7Active, compsAllActive, compsAll2Active,
  handleHistoricalComps, onFetchNarrative, handleNotesChange, handleWideStreetChange, lotUpdateHandlers, compsDate, handleCompsDateUpdate }) => {

  const [narrativeLoading, setNarrativeLoading] = useState(false);
  const [activeComps, setActiveComps] = useState(AllVersions);

  // Toggle function to add/remove comp version from the activeComps list
  const toggleComp = (compVersion) => {
    // Check if the version is already active
    if (activeComps.includes(compVersion)) {
      // If active, remove from both local state and global state
      setActiveComps((prevActiveComps) => prevActiveComps.filter((comp) => comp !== compVersion));
      AllVersions = AllVersions.filter((comp) => comp !== compVersion); // Update global state
    } else {
      // If not active, add to both local state and global state
      setActiveComps((prevActiveComps) => [...prevActiveComps, compVersion]);
      AllVersions.push(compVersion); // Update global state
    }
  };

  const goTo = (page) => {
    // Navigate doesnt allow to open to new tab
    // navigate(
    //   '/' + page + '/' + selectedLot.bbl
    // )
    window.open('/' + page + '/' + selectedLot.propertyId, '_blank')
  }

  return (
    <>
      <div style={{ maxHeight: '250px' }}>
        {/* {isUserAdmin() ? (
                  <>
                    <Button title='Owned' basic className={`no-border no-padding ${selectedLot.isOwned === undefined ? 'loading' : ''}`} icon={{ name: 'certificate', color: selectedLot.isOwned ? 'red' : '' }} floated='right' size='big' onClick={e => { handleAddToOwnership(e, selectedLot) }}></Button>
                    <Button title='Contract' basic className={`no-border no-padding ${selectedLot.isContract === undefined ? 'loading' : ''}`} icon={{ name: selectedLot.isContract ? 'file alternate' : 'file alternate outline', color: selectedLot.isContract ? 'blue' : '' }} floated='right' size='big' onClick={e => { handleAddToContract(e, selectedLot) }}></Button>
                    <Button title='Watch' basic className={`no-border no-padding ${selectedLot.isWatchlistActive === undefined ? 'loading' : ''}`} icon={{ name: selectedLot.isWatchlistActive ? 'bookmark' : 'bookmark outline', color: selectedLot.isWatchlistActive ? 'yellow' : '' }} floated='right' size='big' onClick={e => { handleAddToWatchlist(e, selectedLot) }}></Button>
                  </>
                ) : null} */}

        <section>
          <Header as='h3' className="clear-bottom">
            <span className="address">{Lot.fullAddress(selectedLot)}</span>
          </Header>
          {/* <p className="text-small dark-gray clear-bottom">{selectedLot.borocode}-{selectedLot.block}-{selectedLot.lot}</p> */}
          <p style={{ minHeight: '20px' }} className="text-small dark-gray clear-bottom">
            {selectedLot.propertyId} {!Lot.isPluto(selectedLot) && (selectedLot.block || selectedLot.lot) ? (<span>({selectedLot.block && `B: ${selectedLot.block} `}{selectedLot.lot && `L: ${selectedLot.lot}`})</span>) : null}
            
          </p>
          <p style={{ minHeight: '20px' }} className="text-small dark-gray">{selectedLot.neighborhood} {selectedLot.subdivision ? `(${selectedLot.subdivision})` : ''}</p>
          <Segment className='no-padding' basic textAlign='center'>
            <Input
              size='mini'
              type='date'
              value={compsDate}
              onChange={(e, { value }) => {
                handleCompsDateUpdate && handleCompsDateUpdate(value)
              }}
            />
            <div>
            <Button.Group size='mini' style={{marginBottom: '4px'}}>
              <Button disabled={selectedLot.propertyType === 'V' || Lot.bldgarea(selectedLot) === 0} className='btn_comps' onClick={handleComps} toggle active={compsActive}>1</Button>
              <Button onClick={handleComps5} className='btn_comps' toggle active={comps5Active}>5</Button>
              <Button onClick={handleComps3} className='btn_comps' toggle active={comps3Active}>5.1</Button>
              <Button onClick={handleComps4} className='btn_comps' toggle active={comps4Active}>6</Button>
              <Button onClick={handleComps2} className='btn_comps' toggle active={comps2Active}>6.1</Button>
              <Button onClick={handleComps6} className='btn_comps' toggle active={comps6Active}>6.1P</Button>
              <Button onClick={handleComps6C} className='btn_comps' toggle active={comps6CActive}>6.1C</Button>
              <Button onClick={handleComps6D} className='btn_comps' toggle active={comps6DActive}>6.1D</Button>
              <Button onClick={handleComps7} className='btn_comps' toggle active={comps7Active}>7</Button>
            </Button.Group>{' '}
            <Button.Group size='mini'>
              <Button onClick={handleAllComps} className='btn_comps' toggle active={compsAllActive}>All v1</Button>
              <Button onClick={handleAll2Comps} className='btn_comps' toggle active={compsAll2Active}>All v2</Button>
            {Lot.isFA(selectedLot) && !isMobile ? (
                // <Button disabled={selectedLot.propertyType === 'V' || Lot.bldgarea(selectedLot) === 0} className='btn_comps' onClick={handleCombinedComps} toggle active={combinedCompsActive}>Comps+</Button>
                <Button disabled={selectedLot.propertyType === 'V' || Lot.bldgarea(selectedLot) === 0} className='btn_comps' onClick={handleHistoricalComps} toggle>Historical</Button>
              ) : null}
              {/* {Lot.isFA(selectedLot) && !isMobile ? (
                <Button disabled={selectedLot.propertyType === 'V' || Lot.bldgarea(selectedLot) === 0} className='btn_comps' onClick={handleCombinedForcedComps} toggle active={combinedCompsActive}>Comps++</Button>
              ) : null} */}
            </Button.Group>{' '}
            <Button.Group size='mini'>
              <Button onClick={() => goTo('fastclose')}>FastClose</Button>
              <Button onClick={() => goTo('comp-appraisals')}>Appraisals</Button>
              {/* {selectedLot && selectedLot.narrative && selectedLot.narrative.length > 0 ? <Modal
                    trigger={<Button>Narrative</Button>}
                    size='tiny'
                  >
                <LotNarrative narrative={selectedLot?.narrative} />
              </Modal>
              : <Button loading={narrativeLoading} disabled={narrativeLoading} onClick={() => {
                onFetchNarrative()
                setNarrativeLoading(true)
              }}>Narrative</Button>} */}
            </Button.Group>
            <Button.Group size='micro' style={{ marginTop: '4px' }}>
              <Button
                onClick={() => toggleComp(1)}
                className='btn_comps'
                toggle
                active={activeComps.includes(1)}
              >
                1
              </Button>
              <Button
                onClick={() => toggleComp(2)}
                className='btn_comps'
                toggle
                active={activeComps.includes(2)}
              >
                2
              </Button>
              <Button
                onClick={() => toggleComp(3)}
                className='btn_comps'
                toggle
                active={activeComps.includes(3)}
              >
                3
              </Button>
              <Button
                onClick={() => toggleComp(4)}
                className='btn_comps'
                toggle
                active={activeComps.includes(4)}
              >
                4
              </Button>
              <Button
                onClick={() => toggleComp(5)}
                className='btn_comps'
                toggle
                active={activeComps.includes(5)}
              >
                5
              </Button>
              <Button
                onClick={() => toggleComp(6)}
                className='btn_comps'
                toggle
                active={activeComps.includes(6)}
              >
                5.1
              </Button>
              <Button
                onClick={() => toggleComp(7)}
                className='btn_comps'
                toggle
                active={activeComps.includes(7)}
              >
                6
              </Button>
              <Button
                onClick={() => toggleComp(8)}
                className='btn_comps'
                toggle
                active={activeComps.includes(8)}
              >
                6.1
              </Button>
              <Button
                onClick={() => toggleComp(9)}
                className='btn_comps'
                toggle
                active={activeComps.includes(9)}
              >
                6.1P
              </Button>
              <Button
                onClick={() => toggleComp(10)}
                className='btn_comps'
                toggle
                active={activeComps.includes(10)}
              >
                6.1C
              </Button>
              <Button
                onClick={() => toggleComp(11)}
                className='btn_comps'
                toggle
                active={activeComps.includes(11)}
              >
                6.1D
              </Button>
              <Button
                onClick={() => toggleComp(12)}
                className='btn_comps'
                toggle
                active={activeComps.includes(12)}
              >
                7
              </Button>
            </Button.Group>
            </div>
                
          </Segment>

          <LotBadgesPanel lot={selectedLot} />
          <Divider />
        </section>

        {Lot.isPluto(selectedLot) && <section>
          <LotStatsPanel lot={selectedLot} />
          <Divider />
        </section>}
      </div>

      {!isMobile ? (
        <PerfectScrollbar style={{ height: '100%' }} options={{ suppressScrollX: true }}>
          <LotInformationSection selectedLot={selectedLot}
            lotUpdateHandlers={lotUpdateHandlers} handleWideStreetChange={handleWideStreetChange}
          />
          <CityDataRow lot={selectedLot} />
        </PerfectScrollbar>
      ) : (
        <LotInformationSection selectedLot={selectedLot}
          lotUpdateHandlers={lotUpdateHandlers} handleWideStreetChange={handleWideStreetChange}
        />
      )}

      <section>
        <ContainedAccordian>
          <Grid className="lot-map" textAlign='left' columns={1}>
            <Grid.Row>
              <Grid.Column stretched={true}>
                <Form>
                  <TextArea key={selectedLot.lotNotes} onBlur={e => { handleNotesChange(e, selectedLot) }} placeholder='Notes...' style={{ minHeight: 50, maxHeight: 50 }} defaultValue={selectedLot.lotNotes} />
                </Form>
                <Divider hidden={true} />
                <GoogleMap
                  //compsPanelVisible={compsVisible}
                  latitude={selectedLot.latitude}
                  longitude={selectedLot.longitude}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </ContainedAccordian>
      </section>
    </>
  )
}

const HorizontalSidebar = ({ compsActive, combinedCompsActive, comps2Active, comps3Active, comps4Active, comps5Active, comps6Active, comps6CActive, comps6DActive, comps7Active, compsAllActive, compsAll2Active,
   handleCombinedComps, handleCombinedForcedComps, handleComps, handleHistoricalComps, onFetchNarrative,
   handleComps2, handleComps3, handleComps4, handleComps5, handleComps6, handleComps6C, handleComps6D, handleComps7, handleAllComps, handleAll2Comps,
   handleFetchDocumentConnections, handleAddToWatchlist, handleAddToOwnership, handleAddToContract, handlePanelOpen, handlePanelClose,
  handleAnimationChange, handleNotesChange, handleWideStreetChange, animation, direction, visible, width, selectedLot, lotUpdateHandlers, compsDate, handleCompsDateUpdate }) => {
  //const navigate = useNavigate();

  return (
    <Sidebar
      className='side-panel-container'
      as={Segment}
      animation={animation}
      direction={direction}
      width={width}
      visible={visible}
      onVisible={handlePanelOpen}
    >
      <Grid className='side-panel-grid' style={{ height: '100%' }}>
        <Label className='panel-closer left' basic icon={`long arrow alternate ${visible ? 'right' : 'left'}`} floating onClick={handleAnimationChange}></Label>
        <Grid.Row columns={1} style={{ height: '100%' }}>
          <Grid.Column stretched style={{ height: '100%' }}>
            {selectedLot ? (
              <FullLotInformation
                selectedLot={selectedLot}
                compsActive={compsActive}
                combinedCompsActive={combinedCompsActive}
                comps2Active={comps2Active}
                comps3Active={comps3Active}
                comps4Active={comps4Active}
                comps5Active={comps5Active}
                comps6Active={comps6Active}
                comps6CActive={comps6CActive}
                comps6DActive={comps6DActive}
                comps7Active={comps7Active}
                compsAllActive={compsAllActive}
                compsAll2Active={compsAll2Active}
                handleCombinedComps={handleCombinedComps}
                handleCombinedForcedComps={handleCombinedForcedComps}
                handleComps={handleComps}
                handleHistoricalComps={handleHistoricalComps}
                handleComps2={handleComps2}
                handleComps3={handleComps3}
                handleComps4={handleComps4}
                handleComps5={handleComps5}
                handleComps6={handleComps6}
                handleComps6C={handleComps6C}
                handleComps6D={handleComps6D}
                handleComps7={handleComps7}
                handleAllComps={handleAllComps}
                handleAll2Comps={handleAll2Comps}
                handleNotesChange={handleNotesChange}
                handleWideStreetChange={handleWideStreetChange}
                lotUpdateHandlers={lotUpdateHandlers}
                compsDate={compsDate}
                handleCompsDateUpdate={handleCompsDateUpdate}
                onFetchNarrative={onFetchNarrative}
              />
            ) : (
              <>
                <section>
                  <Placeholder>
                    <Header as='h3' className="clear-bottom">
                      <Placeholder.Line length="full" />
                    </Header>
                    <Placeholder.Line length="long" />
                    <Placeholder.Line length="medium" />
                  </Placeholder>
                  <Divider style={{ marginTop: "1.5rem" }} />
                </section>

                <section>
                  <div className='stats-group'>
                    <div>
                      {Array.from(["DOB", "Complaints", "OATH", "HPD"], (e, i) => {
                        return <PlaceholderStatSet key={i} title={e} />
                      })}
                    </div>
                  </div>
                  <Divider />
                </section>

                <div>
                  <div>
                    <section>

                      <Grid className="lot-details" columns={2}>

                        {Array.from(Array(14), (e, i) => {
                          return <PlaceholderTableLine key={i} length={i} />
                        })}

                        <Divider />

                        {Array.from(Array(2), (e, i) => {
                          return <PlaceholderTableLine key={i} length={i} />
                        })}

                        <Divider />

                        {Array.from(Array(2), (e, i) => {
                          return <PlaceholderTableLine key={i} length={i} />
                        })}

                        <Divider />

                        {Array.from(Array(6), (e, i) => {
                          return <PlaceholderTableLine key={i} length={i} />
                        })}

                        <Divider />

                        {/* <Grid.Row>
                    <Grid.Column width={16}>
                    <Placeholder fluid>
                        <Placeholder.Image rectangular />
                    </Placeholder>
                    </Grid.Column>
                  </Grid.Row> */}

                      </Grid>

                    </section>
                    <section>
                      <Grid className="lot-notes" textAlign='left' columns={1}>
                        <Grid.Row>
                          <Grid.Column stretched={true}>
                            <Placeholder>
                              <Placeholder.Paragraph>
                                <Placeholder.Line />
                                <Placeholder.Line />
                                <Placeholder.Line />
                                <Placeholder.Line />
                                <Placeholder.Line />
                              </Placeholder.Paragraph>
                            </Placeholder>
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </section>
                  </div>
                </div>
              </>
            )
            }
          </Grid.Column >
        </Grid.Row >
      </Grid >
    </Sidebar >
  )
}

export default HorizontalSidebar;