import React from 'react';

import {
    Search,
    Input
  } from 'semantic-ui-react'

import SearchInput from './extras/SearchInput'

const resultRenderer = ({ title }) => <label style={{fontSize: '12px'}}>{title.toUpperCase()}</label>

const SearchPanel = ({lng, lat, zoom, text, searchState, handleResultSelect, handleSearchChange}) => (

  <div id="omnibox" className="vasquette-margin-enabled">
    {/* <div className='sidebarStyle'>
    <div>Longitude: {lng} | Latitude: {lat} | Zoom: {zoom}</div>
    <div>{text}</div>
    </div> */}
    <SearchInput className="fluid" style={{ marginRight: '0.5em' }} name='' label='' placeholder='' onChange={handleSearchChange} />
    {/* <Search
        style={{marginRight: '0.5em'}}
        as={Input}
        size='small'
        fluid
        loading={isLoading}
        onResultSelect={handleResultSelect}
        onSearchChange={handleSearchChange}
        results={searchState.results}
        //value={text}
        resultRenderer={resultRenderer}
        /> */}
  </div>
)

export default SearchPanel