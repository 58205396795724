import moment from 'moment'

import faSiteInfluenceCode from './assets/json/fa/site_influence_code.json'
import faAmenities from './assets/json/fa/amenities.json'
import { DataSetType } from './utility/Global';
import { roundToFloorByNearest, roundToNearest } from './utility/Helper';

function formatNumber(amount) {
  // Create our number formatter.
  var formatter = new Intl.NumberFormat('en-US', {
    maximumFractionDigits: 2
  });
  
  return formatter.format(amount);
  };


  const fullBldgclassLookup = {
    A0: 'One Family Dwellings - Cape Cod',
    A1: 'One Family Dwellings - Two Stories Detached (Small or Moderate Size, With or Without Attic)',
    A2: 'One Family Dwellings - One Story (Permanent Living Quarters)',
    A3: 'One Family Dwellings - Large Suburban Residence',
    A4: 'One Family Dwellings - City Residence',
    A5: 'One Family Dwellings - Attached or Semi-Detached',
    A6: 'One Family Dwellings - Summer Cottages',
    A7: 'One Family Dwellings - Mansion Type or Town House',
    A8: 'One Family Dwellings - Bungalow Colony/Land Coop Owned',
    A9: 'One Family Dwellings - Miscellaneous',
  
    B1: 'Two Family Dwellings - Brick',
    B2: 'Frame',
    B3: 'Converted From One Family',
    B9: 'Miscellaneous',
  
    C0: 'Walk-up Apartments - Three Families',
    C1: 'Walk-up Apartments - Over Six Families Without Stores',
    C2: 'Walk-up Apartments - Five to Six Families',
    C3: 'Walk-up Apartments - Four Families',
    C4: 'Walk-up Apartments - Old Law Tenements',
    C5: 'Walk-up Apartments - Converted Dwelling or Rooming House',
    C6: 'Walk-up Apartments - Cooperative',
    C7: 'Walk-up Apartments - Over Six Families With Stores',
    C8: 'Walk-up Apartments - Co-Op Conversion From Loft/Warehouse',
    C9: 'Walk-up Apartments - Garden Apartments',
    CM: 'Mobile Homes/Trailer Parks',
  
    D0: 'Elevator Apartments - Co-op Conversion from Loft/Warehouse',
    D1: 'Elevator Apartments - Semi-fireproof (Without Stores)',
    D2: 'Elevator Apartments - Artists in Residence',
    D3: 'Elevator Apartments - Fireproof (Without Stores)',
    D4: 'Elevator Apartments - Cooperatives (Other Than Condominiums)',
    D5: 'Elevator Apartments - Converted',
    D6: 'Elevator Apartments - Fireproof With Stores',
    D7: 'Elevator Apartments - Semi-Fireproof With Stores',
    D8: 'Elevator Apartments - Luxury Type',
    D9: 'Elevator Apartments - Miscellaneous',
  
    E1: 'Warehouses - Fireproof',
    E2: 'Warehouses - Contractor’s Warehouse',
    E3: 'Warehouses - Semi-Fireproof',
    E4: 'Warehouses - Frame, Metal',
    E7: 'Warehouses - Warehouse, Self Storage',
    E9: 'Warehouses - Miscellaneous',
  
    F1: 'Factory and Industrial Buildings - Heavy Manufacturing - Fireproof',
    F2: 'Factory and Industrial Buildings - Special Construction - Fireproof',
    F4: 'Factory and Industrial Buildings - Semi-Fireproof',
    F5: 'Factory and Industrial Buildings - Light Manufacturing',
    F8: 'Factory and Industrial Buildings - Tank Farms',
    F9: 'Factory and Industrial Buildings - Miscellaneous',
  
    G: 'GARAGES AND GASOLINE STATIONS',
    G0: 'Residential Tax Class 1 Garage',
    G1: 'All Parking Garages',
    G2: 'Auto Body/Collision or Auto Repair',
    G3: 'Gas Station with Retail Store',
    G4: 'Gas Station with Service/Auto Repair',
    G5: 'Gas Station only with/without Small Kiosk',
    G6: 'Licensed Parking Lot',
    G7: 'Unlicensed Parking Lot',
    G8: 'Car Sales/Rental with Showroom',
    G9: 'Miscellaneous Garage or Gas Station',
    GU: 'Car Sales/Rental without Showroom',
    GW: 'Car Wash or Lubritorium Facility',
  
    H1: 'Hotels - Luxury Type',
    H2: 'Hotels - Full Service Hotel',
    H3: 'Hotels - Limited Service – Many Affiliated with National Chain',
    H4: 'Hotels - Motels',
    H5: 'Hotels - Private Club, Luxury Type',
    H6: 'Hotels - Apartment Hotels',
    H7: 'Hotels - Apartment Hotels-Co-op Owned',
    H8: 'Hotels - Dormitories',
    H9: 'Hotels - Miscellaneous',
    HB: 'Hotels - Boutique 10-100 Rooms, with Luxury Facilities, Themed, Stylish, with Full Service Accommodations',
    HH: 'Hotels - Hostel-Bed Rental in Dorm Like Setting with Shared Rooms & Bathrooms',
    HR: 'Hotels - SRO- 1 or 2 People Housed in Individual Rooms in Multiple Dwelling Affordable Housing',
    HS: 'Hotels - Extended Stay/Suite Amenities Similar to Apt., Typically Charge Weekly Rates & Less Expensive than Full Service Hotel',
  
    I1: 'Hospitals and Health - Hospitals, Sanitariums, Mental Institutions',
    I2: 'Hospitals and Health - Infirmary',
    I3: 'Hospitals and Health - Dispensary',
    I4: 'Hospitals and Health - Staff Facilities',
    I5: 'Hospitals and Health - Health Center, Child Center, Clinic',
    I6: 'Hospitals and Health - Nursing Home',
    I7: 'Hospitals and Health - Adult Care Facility',
    I9: 'Hospitals and Health - Miscellaneous',
  
    J1: 'Theatres - Art Type (Seating Capacity under 400 Seats)',
    J2: 'Theatres - Art Type (Seating Capacity Over 400 Seats)',
    J3: 'Theatres - Motion Picture Theatre with Balcony',
    J4: 'Theatres - Legitimate Theatres (Theatre Sole Use of Building)',
    J5: 'Theatres - Theatre in Mixed Use Building',
    J6: 'Theatres - T.V. Studios',
    J7: 'Theatres - Off-Broadway Type',
    J8: 'Theatres - Multiplex Picture Theatre',
    J9: 'Theatres - Miscellaneous',
  
    K1: 'Store Buildings (Taxpayers Included) - One Story Retail Building',
    K2: 'Store Buildings (Taxpayers Included) - Multi-Story Retail Building',
    K3: 'Store Buildings (Taxpayers Included) - Multi-Story Department Store',
    K4: 'Store Buildings (Taxpayers Included) - Predominant Retail with Other Uses',
    K5: 'Store Buildings (Taxpayers Included) - Stand Alone Food Establishment',
    K6: 'Store Buildings (Taxpayers Included) - Shopping Centers With or Without Parking',
    K7: 'Store Buildings (Taxpayers Included) - Banking Facilities with or Without Parking',
    K8: 'Store Buildings (Taxpayers Included) - Big Box Retail Not Affixed & Standing On Own Lot with Parking',
    K9: 'Store Buildings (Taxpayers Included) - Miscellaneous',
  
    L1: 'Loft Buildinghs - Over Eight Stores (Mid-Manhattan Type)',
    L2: 'Loft Buildinghs - Fireproof and Storage Type (Without Stores)',
    L3: 'Loft Buildinghs - Semi-Fireproof',
    L8: 'Loft Buildinghs - With Retail Stores Other Than Type 1',
    L9: 'Loft Buildinghs - Miscellaneous',
  
    M1: 'Churches, Synagogues, etc. - Church, Synagogue, Chapel',
    M2: 'Churches, Synagogues, etc. - Mission House (Non-Residential)',
    M3: 'Churches, Synagogues, etc. - Parsonage, Rectory',
    M4: 'Churches, Synagogues, etc. - Convents',
    M9: 'Churches, Synagogues, etc. - Miscellaneous',
  
    N1: 'Asylums and Homes - Asylums',
    N2: 'Asylums and Homes - Homes for Indigent Children, Aged, Homeless',
    N3: 'Asylums and Homes - Orphanages',
    N4: 'Asylums and Homes - Detention House For Wayward Girls',
    N9: 'Asylums and Homes - Miscellaneous',
  
    O1: 'Office Buildings - Office Only – 1 Story',
    O2: 'Office Buildings - Office Only – 2-6 Stories',
    O3: 'Office Buildings - Office Only – 7-19 Stories',
    O4: 'Office Buildings - Office Only or Office with Comm – 20 Stories or More',
    O5: 'Office Buildings - Office with Comm – 1 to 6 Stories',
    O6: 'Office Buildings - Office with Comm – 7 to 19 Stories',
    O7: 'Office Buildings - Professional Buildings/Stand Alone Funeral Homes',
    O8: 'Office Buildings - Office with Apartments Only (No Comm)',
    O9: 'Office Buildings - Miscellaneous and Old Style Bank Bldgs',
  
    P1: 'Places of Public Assembly (indoor) and Cultural - Concert Halls',
    P2: 'Places of Public Assembly (indoor) and Cultural - Lodge Rooms',
    P3: 'Places of Public Assembly (indoor) and Cultural - YWCA, YMCA, YWHA, YMHA, PAL',
    P4: 'Places of Public Assembly (indoor) and Cultural - Beach Club',
    P5: 'Places of Public Assembly (indoor) and Cultural - Community Center',
    P6: 'Places of Public Assembly (indoor) and Cultural - Amusement Place, Bathhouse, Boat House',
    P7: 'Places of Public Assembly (indoor) and Cultural - Museum',
    P8: 'Places of Public Assembly (indoor) and Cultural - Library',
    P9: 'Places of Public Assembly (indoor) and Cultural - Miscellaneous',
  
    Q0: 'Outdoor Recreation Facilities - Open Space',
    Q1: 'Outdoor Recreation Facilities - Parks/Recreation Facilities',
    Q2: 'Outdoor Recreation Facilities - Playground',
    Q3: 'Outdoor Recreation Facilities - Outdoor Pool',
    Q4: 'Outdoor Recreation Facilities - Beach',
    Q5: 'Outdoor Recreation Facilities - Golf Course',
    Q6: 'Outdoor Recreation Facilities - Stadium, Race Track, Baseball Field',
    Q7: 'Outdoor Recreation Facilities - Tennis Court',
    Q8: 'Outdoor Recreation Facilities - Marina, Yacht Club',
    Q9: 'Outdoor Recreation Facilities - Miscellaneous',
  
    R0: 'Condominiums - Condo Billing Lot',
    R1: 'Condominiums - Residential Unit in 2-10 Unit Bldg',
    R2: 'Condominiums - Residential Unit in Walk-Up Bldg',
    R3: 'Condominiums - Residential Unit in 1-3 Story Bldg',
    R4: 'Condominiums - Residential Unit in Elevator Bldg',
    R5: 'Condominiums - Miscellaneous Commercial',
    R6: 'Condominiums - Residential Unit of 1-3 Unit Bldg-Orig Class 1',
    R7: 'Condominiums - Commercial Unit of 1-3 Units Bldg- Orig Class 1',
    R8: 'Condominiums - Commercial Unit of 2-10 Unit Bldg',
    R9: 'Condominiums - Co-op within a Condominium',
    RA: 'Condominiums - Cultural, Medical, Educational, etc.',
    RB: 'Condominiums - Office Space',
    RC: 'Condominiums - Commercial Building (Mixed Commercial Condo Building Classification Codes)',
    RD: 'Condominiums - Residential Building (Mixed Residential Condo Building Classification Codes)',
    RG: 'Condominiums - Indoor Parking',
    RH: 'Condominiums - Hotel/Boatel',
    RI: 'Condominiums - Mixed Warehouse/Factory/Industrial & Commercial',
    RK: 'Condominiums - Retail Space',
    RM: 'Condominiums - Mixed Residential & Commercial Building (Mixed Residential & Commercial)',
    RP: 'Condominiums - Outdoor Parking',
    RR: 'Condominiums - Condominium Rentals',
    RS: 'Condominiums - Non-Business Storage Space',
    RT: 'Condominiums - Terraces/Gardens/Cabanas',
    RW: 'Condominiums - Warehouse/Factory/Industrial',
    RX: 'Condominiums - Mixed Residential, Commercial & Industrial',
    RZ: 'Condominiums - Mixed Residential & Warehouse',
  
    S0: 'Residence (Multiple Use) - Primarily One Family with Two Stores or Offices',
    S1: 'Residence (Multiple Use) - Primarily One Family with One Store or Office',
    S2: 'Residence (Multiple Use) - Primarily Two Family with One Store or Office',
    S3: 'Residence (Multiple Use) - Primarily Three Family with One Store or Office',
    S4: 'Residence (Multiple Use) - Primarily Four Family with One Store or Office',
    S5: 'Residence (Multiple Use) - Primarily Five to Six Family with One Store or Office',
    S9: 'Residence (Multiple Use) - Single or Multiple Dwelling with Stores or Offices',
  
    T1: 'Transportation Facilities (Assessed in ORE) - Airport, Air Field, Terminal',
    T2: 'Transportation Facilities (Assessed in ORE) - Pier, Dock, Bulkhead',
    T9: 'Transportation Facilities (Assessed in ORE) - Miscellaneous',
  
    U0: 'Utility Bureau Properties - Utility Company Land and Building',
    U1: 'Utility Bureau Properties - Bridge, Tunnel, Highway',
    U2: 'Utility Bureau Properties - Gas or Electric Utility',
    U3: 'Utility Bureau Properties - Ceiling Railroad',
    U4: 'Utility Bureau Properties - Telephone Utility',
    U5: 'Utility Bureau Properties - Communications Facilities Other Than Telephone',
    U6: 'Utility Bureau Properties - Railroad - Private Ownership',
    U7: 'Utility Bureau Properties - Transportation - Public Ownership',
    U8: 'Utility Bureau Properties - Revocable Consent',
    U9: 'Utility Bureau Properties - Miscellaneous',
  
    V0: 'Vacant Land - Zoned Residential; Not Manhattan',
    V1: 'Vacant Land - Zoned Commercial or Manhattan Residential',
    V2: 'Vacant Land - Zoned Commercial Adjacent to Class 1 Dwelling; Not Manhattan',
    V3: 'Vacant Land - Zoned Primarily Residential; Not Manhattan',
    V4: 'Vacant Land - Police or Fire Department',
    V5: 'Vacant Land - School Site or Yard',
    V6: 'Vacant Land - Library, Hospital or Museum',
    V7: 'Vacant Land - Port Authority of NY and NJ',
    V8: 'Vacant Land - New York State & U.S. Government',
    V9: 'Vacant Land - Miscellaneous',
  
    W1: 'Educational Structures - Public Elementary, Junior or Senior High',
    W2: 'Educational Structures - Parochial School, Yeshiva',
    W3: 'Educational Structures - School or Academy',
    W4: 'Educational Structures - Training School',
    W5: 'Educational Structures - City University',
    W6: 'Educational Structures - Other College and University',
    W7: 'Educational Structures - Theological Seminary',
    W8: 'Educational Structures - Other Private School',
    W9: 'Educational Structures - Miscellaneous',
  
    Y1: 'Selected Government Installations (Excluding Office Buildings, Training Schools, Academic, Garages, Warehouses, Piers, Air Fields, Vacant Land, Vacant Sites, and Land Under Water and Easements) - Fire Department',
    Y2: 'Selected Government Installations (Excluding Office Buildings, Training Schools, Academic, Garages, Warehouses, Piers, Air Fields, Vacant Land, Vacant Sites, and Land Under Water and Easements) - Police Department',
    Y3: 'Selected Government Installations (Excluding Office Buildings, Training Schools, Academic, Garages, Warehouses, Piers, Air Fields, Vacant Land, Vacant Sites, and Land Under Water and Easements) - Prison, Jail, House of Detention',
    Y4: 'Selected Government Installations (Excluding Office Buildings, Training Schools, Academic, Garages, Warehouses, Piers, Air Fields, Vacant Land, Vacant Sites, and Land Under Water and Easements) - Military and Naval Installation',
    Y5: 'Selected Government Installations (Excluding Office Buildings, Training Schools, Academic, Garages, Warehouses, Piers, Air Fields, Vacant Land, Vacant Sites, and Land Under Water and Easements) - Department of Real Estate',
    Y6: 'Selected Government Installations (Excluding Office Buildings, Training Schools, Academic, Garages, Warehouses, Piers, Air Fields, Vacant Land, Vacant Sites, and Land Under Water and Easements) - Department of Sanitation',
    Y7: 'Selected Government Installations (Excluding Office Buildings, Training Schools, Academic, Garages, Warehouses, Piers, Air Fields, Vacant Land, Vacant Sites, and Land Under Water and Easements) - Department of Ports and Terminals',
    Y8: 'Selected Government Installations (Excluding Office Buildings, Training Schools, Academic, Garages, Warehouses, Piers, Air Fields, Vacant Land, Vacant Sites, and Land Under Water and Easements) - Department of Public Works',
    Y9: 'Selected Government Installations (Excluding Office Buildings, Training Schools, Academic, Garages, Warehouses, Piers, Air Fields, Vacant Land, Vacant Sites, and Land Under Water and Easements) - Department of Environmental Protection',
  
    Z0: 'Miscellaneous - Tennis Court, Pool, Shed, etc.',
    Z1: 'Miscellaneous - Court House',
    Z2: 'Miscellaneous - Public Parking Area',
    Z3: 'Miscellaneous - Post Office',
    Z4: 'Miscellaneous - Foreign Government',
    Z5: 'Miscellaneous - United Nations',
    Z7: 'Miscellaneous - Easement',
    Z8: 'Miscellaneous - Cemetery',
    Z9: 'Miscellaneous - Other',
  };
  
  
const bldgclassLookup = {
    A0: 'Cape Cod',
    A1: 'Two Stories Detached (Small or Moderate Size, With or Without Attic)',
    A2: 'One Story (Permanent Living Quarters)',
    A3: 'Large Suburban Residence',
    A4: 'City Residence',
    A5: 'Attached or Semi-Detached',
    A6: 'Summer Cottages/Mobile Homes/Trailers',
    A7: 'Mansion Type',
    A8: 'Bungalow Colony/Land Coop Owned',
    A9: 'Miscellaneous (Old Buildings, Attached & Semi-Detached Frame Houses, etc.)',
  
    B1: 'Brick',
    B2: 'Frame',
    B3: 'Converted (From One Family)',
    B9: 'Miscellaneous (City, Type, Old, etc.)',
  
    C0: 'Three Families',
    C1: 'Over Six Families Without Stores',
    C2: 'Five to Six Families',
    C3: 'Four Families',
    C4: 'Old Law Tenements',
    C5: 'Converted Dwelling or Rooming House',
    C6: 'Cooperative (Other Than Condominiums)',
    C7: 'Over Six Families With Stores',
    C8: 'Co-Op Conversion From Loft/Warehouse',
    C9: 'Garden Apartments',
    CM: 'Mobile Homes/Trailer Parks',
  
    D0: 'Co-op Conversion from Loft/Warehouse',
    D1: 'Semi-fireproof (Without Stores)',
    D2: 'Artists in Residence',
    D3: 'Fireproof (Standard Construction Without Stores)',
    D4: 'Cooperatives (Other Than Condominiums)',
    D5: 'Converted',
    D6: 'Fireproof - With Stores',
    D7: 'Semi-Fireproof With Stores',
    D8: 'Luxury Type',
    D9: 'Miscellaneous',
  
    E1: 'Fireproof',
    E2: 'Contractor’s Warehouse',
    E3: 'Semi-Fireproof',
    E4: 'Frame, Metal',
    E7: 'Warehouse, Self Storage',
    E9: 'Miscellaneous',
  
    F1: 'Heavy Manufacturing (Fireproof)',
    F2: 'Special Construction (Printing Plant, etc., Fireproof)',
    F4: 'Semi-Fireproof',
    F5: 'Light Manufacturing',
    F8: 'Tank Farms',
    F9: 'Miscellaneous',
  
    G: 'GARAGES AND GASOLINE STATIONS',
    G0: 'Residential Tax Class 1 Garage',
    G1: 'Garage - Two or More Stories',
    G2: 'Garage - One Story (Semi-Fireproof or Fireproof',
    G3: 'Garage and Gas Station Combined',
    G4: 'Gas Station - With Enclosed Lubrication Plant or Workshop',
    G5: 'Gas Station - Without Enclosed Lubrication Plant or Workshop',
    G6: 'Licensed Parking Lot',
    G7: 'Unlicensed Parking Lot',
    G8: 'Garage With Showroom',
    G9: 'Miscellaneous',
    GU: 'Car Sales/Rental without Showroom',
    GW: 'Car Wash or Lubritorium Facility',
  
    H1: 'Luxury Type - Built Prior to 1960',
    H2: 'Luxury Type - Built After 1960',
    H3: 'Transient Occupancy-Midtown Mn Area',
    H4: 'Motels',
    H5: 'Private Club, Luxury Type',
    H6: 'Apartment Hotels',
    H7: 'Apartment Hotels-Co-op Owned',
    H8: 'Dormitories',
    H9: 'Miscellaneous',
    HB: 'Boutique 10-100 Rooms, with Luxury Facilities, Themed, Stylish, with Full Service Accommodations',
    HH: 'Hostel-Bed Rental in Dorm Like Setting with Shared Rooms & Bathrooms',
    HR: 'SRO- 1 or 2 People Housed in Individual Rooms in Multiple Dwelling Affordable Housing',
    HS: 'Extended Stay/Suite Amenities Similar to Apt., Typically Charge Weekly Rates & Less Expensive than Full Service Hotel',
  
    I1: 'Hospitals, Sanitariums, Mental Institutions',
    I2: 'Infirmary',
    I3: 'Dispensary',
    I4: 'Staff Facilities',
    I5: 'Health Center, Child Center, Clinic',
    I6: 'Nursing Home',
    I7: 'Adult Care Facility',
    I9: 'Miscellaneous',
  
    J1: 'Art Type (Seating Capacity Under 400 Seats)',
    J2: 'Art Type (Seating Capacity Over 400 Seats)',
    J3: 'Motion Picture Theatre with Balcony',
    J4: 'Legitimate Theatres (Theatre Sole Use of Building)',
    J5: 'Theatre as Part of Building of Other Use',
    J6: 'T.V. Studios',
    J7: 'Off-Broadway Type',
    J8: 'Multi-Motion Picture Theatre',
    J9: 'Miscellaneous',
  
    K1: 'One Story Retail Building',
    K2: 'Two Story or Store and Office',
    K3: 'Department Stores, Multi-Story',
    K4: 'Stores, Apartments Above',
    K5: 'Diners, Franchised Type Stand',
    K6: 'Shopping Centers With Parking Facilities',
    K7: 'Funeral Home',
    K8: 'Big Box Retail Not Affixed & Standing On Own Lot with Parking',
    K9: 'Miscellaneous',
  
    L1: 'Over Eight Stores (Mid-Manhattan Type)',
    L2: 'Fireproof - Loft and Storage Type (Without Stores)',
    L3: 'Semi-Fireproof',
    L8: 'With Retail Stores (Other Than Type 1)',
    L9: 'Miscellaneous',
  
    M1: 'Church, Synagogue, Chapel',
    M2: 'Mission House (Non-Residential)',
    M3: 'Parsonage, Rectory',
    M4: 'Convents',
    M9: 'Miscellaneous',
  
    N1: 'Asylums',
    N2: 'Homes for Indigent Children, Aged, Homeless',
    N3: 'Orphanages',
    N4: 'Juvenile Detention Houses',
    N9: 'Miscellaneous',
  
    O1: 'Fireproof - Up to Nine Stories',
    O2: 'Ten Stories & Over (Side Street Type)',
    O3: 'Ten Stories & Over (Main Avenue Type)',
    O4: 'Tower Type',
    O5: 'Semi-Fireproof',
    O6: 'Bank Building (Designed Exclusively for Banking)',
    O7: 'Professional Buildings',
    O8: 'With Residential Apartments',
    O9: 'Miscellaneous',
  
    P1: 'Concert Halls',
    P2: 'Lodge Rooms',
    P3: 'YWCA, YMCA, YWHA, YMHA, PAL',
    P4: 'Beach Club',
    P5: 'Community Center',
    P6: 'Amusement Places, Bathhouses, Boat House',
    P7: 'Museum',
    P8: 'Library',
    P9: 'Miscellaneous Including Riding Academies and Stables',
  
    Q0: 'Open Space',
    Q1: 'Parks',
    Q2: 'Playgrounds',
    Q3: 'Outdoor Pools',
    Q4: 'Beaches',
    Q5: 'Golf Courses',
    Q6: 'Stadium, Race Tracks, Baseball Fields',
    Q7: 'Tennis Courts',
    Q8: 'Marinas/Yacht Clubs',
    Q9: 'Miscellaneous',
  
    R0: 'Condo Billing Lot',
    R1: '2-10 Unit Residential Bldg, Residential Unit',
    R2: 'Walk-Up, Residential Unit',
    R3: '1-3 Story, Residential Unit',
    R4: 'Apartment/Elevated, Residential Unit',
    R5: 'Commercial Unit',
    R6: '1-3 Units, Residential Unit',
    R7: '1-3 Units, Commercial Unit',
    R8: '2-10 Residential Bldg, Commercial Unit',
    R9: 'Condop',
    RA: 'Cultural, Medical, Educational, Etc.',
    RB: 'Office Space',
    RC: 'Commercial Building (Mixed Commercial Condo Building)',
    RD: 'Residential Building (Mixed Residential Condo Building)',
    RG: 'Indoor Parking',
    RH: 'Hotel/Boatel',
    RI: 'Mixed Warehouse/Factory/Industrial & Commercial',
    RK: 'Retail Space',
    RM: 'Mixed Residential & Commercial',
    RP: 'Outdoor Parking',
    RR: 'Condo Rentals',
    RS: 'Non-Business Storage Space',
    RT: 'Terraces/Gardens/Cabanas',
    RW: 'Warehouse/Factory/Industrial',
    RX: 'Mixed Residential & Commercial Building',
    RZ: 'Mixed Residential & Warehouse',
  
    S0: 'Primarily One Family with Two Stores or Offices',
    S1: 'Primarily One Family with One Store or Office',
    S2: 'Primarily Two Family with One Store or Office',
    S3: 'Primarily Three Family with One Store or Office',
    S4: 'Primarily Four Family with One Store or Office',
    S5: 'Primarily Five to Six Family with One Store or Office',
    S9: 'Single or Multiple Dwelling with Stores or Offices',
  
    T1: 'Airport, Air Field, Terminal',
    T2: 'Pier, Dock, Bulkhead',
    T9: 'Miscellaneous',
  
    U0: 'Utility Company Land and Buildings',
    U1: 'Bridges, Tunnels, Highways',
    U2: 'Electric Utilities, Gas',
    U3: 'Ceiling Railroad',
    U4: 'Telephone Utilities',
    U5: 'Communications Facilities (Other Than Telephone)',
    U6: 'Railroad, Private Ownership',
    U7: 'Transportation, Public Ownership',
    U8: 'Revocable Consent',
    U9: 'Miscellaneous (Including Private Improvements in City Land and in Public Places)',
  
    V0: 'Zoned Residential, Except Not Manhattan Below 110 St',
    V1: 'Not Zoned Residential or Manhattan Below 110 St',
    V2: 'Not Zoned Residential, but Adjacent to Tax Class 1 Dwelling',
    V3: 'Zoned Primarily Residential, Except Not Manhattan Below 110 St',
    V4: 'Police or Fire Department',
    V5: 'School Site or Yard',
    V6: 'Library, Hospital or Museum',
    V7: 'Port Authority of NY and NJ',
    V8: 'State & U.S.',
    V9: 'Miscellaneous (Department of Real Estate and Other Public Places)',
  
    W1: 'Public Elementary Junior and Senior High Schools',
    W2: 'Parochial Schools, Yeshiva',
    W3: 'Schools or Academies',
    W4: 'Training Schools',
    W5: 'City University',
    W6: 'Other Colleges and Universities',
    W7: 'Theological Seminaries',
    W8: 'Other Private Schools',
    W9: 'Miscellaneous',
  
    Y1: 'Fire Department',
    Y2: 'Police Department',
    Y3: 'Prisons, Jails, Houses of Detention',
    Y4: 'Military and Naval',
    Y5: 'Department of Real Estate',
    Y6: 'Department of Sanitation',
    Y7: 'Department of Ports and Terminals',
    Y8: 'Department of Public Works',
    Y9: 'Department of Environmental Protection',
  
    Z0: 'Tennis Court, Pool, Shed, etc.',
    Z1: 'Court House',
    Z2: 'Public Parking Area',
    Z3: 'Post Office',
    Z4: 'Foreign Government',
    Z5: 'United Nations',
    Z6: 'Land under Water',
    Z7: 'Easements',
    Z8: 'Cemeteries',
    Z9: 'Other',
  };
  
 const boroughLookup = {
    BX: 'Bronx',
    BK: 'Brooklyn',
    MN: 'Manhattan',
    QN: 'Queens',
    SI: 'Staten Island',
  };
  
 export const boroLookup = {
    1: 'Manhattan',
    2: 'Bronx',
    3: 'Brooklyn',
    4: 'Queens',
    5: 'Staten Island',
  };

  export const shortBoroLookup = {
    1: 'MN',
    2: 'BX',
    3: 'BK',
    4: 'QN',
    5: 'SI',
  };
  
const ownertypeLookup = {
    C: 'City',
    M: 'Mixed City & Private',
    O: 'Public Authority, State, or Federal',
    P: 'Private',
    X: 'Mixed',
  };
  
const landuseLookup = {
    '01': 'One & Two Family Buildings',
    '02': 'Multi-Family Walk-Up Buildings',
    '03': 'Multi-Family Elevator Buildings',
    '04': 'Mixed Residential & Commercial Buildings',
    '05': 'Commercial & Office Buildings',
    '06': 'Industrial & Manufacturing',
    '07': 'Transportation & Utility',
    '08': 'Public Facilities & Institutions',
    '09': 'Open Space & Outdoor Recreation',
    10: 'Parking Facilities',
    11: 'Vacant Land',
  };


const extensionLookup = {
    E: 'Extension',
    G: 'Garage',
    EG: 'Extension and Garage',
    N: 'None',
  };

  const basementLookup = {
    0: 'None',
    1: 'Above grade full basement',
    2: 'Below grade full basement',
    3: 'Above grade partial basement',
    4: 'Below grade partial basement',
    5: 'Unknown'
  };

  const proximityLookup = {
    0: 'Not Available',
    1: 'Detached',
    2: 'Semi-attached',
    3: 'Attached'
  }

  const lotTypeLookup = {
    0: 'Unknown',
    1: 'Block Assemblage',
    2: 'Waterfront',
    3: 'Corner',
    4: 'Through',
    5: 'Inside',
    6: 'Interior Lot',
    7: 'Island Lot',
    8: 'Alley Lot',
    9: 'Submerged Land Lot'
  }

  const constructionLookup = {
    1: 'Frame',
    2: 'Brick',
    3: 'Stone'
  }

  const propertyTypeLookup = {
    0: 'Vacant',
    1: 'Residential',
    2: 'Commercial',
    3: 'Mixed Use'
  }

  const plutoBoros = ["36061", "36005", "36047", "36081", "36085"]

class Lot {
  static isPluto(lot) {
    return (lot && lot.type && lot.type.toLowerCase() === 'pluto') || (lot && plutoBoros.includes(lot.fips))
  }

  static isFA(lot) {
    return lot && lot.type && lot.type.toLowerCase() === 'fa'
  }

  static isRE(lot) {
    return lot && lot.type && lot.type.toLowerCase() === 're'
  }

  static neighborhood(lot) {
    const {neighborhood, city} = lot || {}
    if (neighborhood && neighborhood.length > 0) return neighborhood
    if (city && city.length > 0) return city
    return null
  }

  static siteInfluences(lot) {
    const {siteInfluenceCode} = lot || {}
    if (!siteInfluenceCode || siteInfluenceCode.length === 0) return 'N/A'
    var influences = []
    for (var i = 0; i < siteInfluenceCode.length; i++) {
      influences.push(faSiteInfluenceCode[siteInfluenceCode.charAt(i)])
    }

    return influences.join(", ")
  } 

  static amenities(lot) {
    const {amenities} = lot || {}
    if (!amenities || amenities.length === 0) return 'N/A'
    var a = []
    for (var i = 0; i < amenities.length; i++) {
      a.push(faAmenities[amenities.charAt(i)])
    }

    return a.join(", ")
  } 

  static lotConstruction(lot) {
    let res = lot.construction
    if (!res) res = lot.constructionCode
    // if (this.isFA(lot)) {
    //    res = faConstructionCode[res] 
    // }
    return res
  }

  static typeFromAddress(city, state) {
    if (state != 'NY') return 'fa'
    if (Object.values(boroLookup).map(_ => _.toLowerCase()).includes(city.toLowerCase())) return DataSetType.PLUTO
    else return DataSetType.FIRST_AMERICAN
  }

  static isNewConstruction(lot) {
    return lot && lot.yearBuilt >= 2000
  }

  static bldgarea(lot) {
    if (lot.listing && lot.listingDetails) {
      const lBldgArea = lot.listingDetails.bldgArea
      const lotArea = lot.lotArea ?? 0
      var val = lot.uBldgArea ? lot.uBldgArea : lot.bldgArea
      if (!val || val === 0 || (lBldgArea > (val * 0.75) && lBldgArea <= (val * 1.9)) && (lot.hasBasment || lot.hasGarage) && lBldgArea != lotArea) {
        return lBldgArea
      } else {
        return val
      }
    }
    return lot.uBldgArea ? lot.uBldgArea : lot.bldgArea
  }

  static proxcode(lot) {
    return lot.uProxCode ? lot.uProxCode : lot.proxCode
  }

  static bedrooms(lot) {
    var bldgarea = this.bldgarea(lot)
    var lotBedrooms = lot.bedrooms && lot.bedrooms > 0 ? lot.bedrooms : 999
    var maxBedrooms = bldgarea < 2000 ? roundToNearest(bldgarea/500) : roundToFloorByNearest(bldgarea/500)
    return Math.min(maxBedrooms, lotBedrooms)
  }

  static residentialUnits(lot) {
    return lot.uResidentialUnits && lot.uResidentialUnits >= 0 ? lot.uResidentialUnits : lot.residentialUnits
  }

  static totalUnits(lot) {
    return lot.uTotalUnits && lot.uTotalUnits >= 0 ? lot.uTotalUnits : lot.totalUnits
  }

  static commericalUnits(lot) {
    return this.totalUnits(lot) - this.residentialUnits(lot)
  }

  static vacateText(orders) {
    const v = orders[0]

    let result = `Vacate Opened: ${moment.utc(v.dateIssued).format('ll')}`

    if (v.dateRescind) {
      result += `\nVacate Closed: ${moment.utc(v.dateRescind).format('ll')}`
    }

    return result
  }

  static ownerName(persons) {
    if (!persons || persons.length === 0) {
      return 'Not Available'
    }

    let person = persons.find(_ => _.name !== '' && _.name !== null && _.type === 2)

    return person.name
  }

  static ownerAddress(persons) {
    if (persons.length === 0) {
      return 'Not Available'
    }
    
    // let person = persons.length === 1 ? persons[0] : persons.find(p => p.name === name);
    // if (person === undefined) {
    //   person = persons[0]
    // }

    let person = persons.find(_ => _.name !== '' && _.name !== null && _.type === 2)

    // let a = person.addressOne
    // if (person.addressTwo != null) {
    //   a += person.addressTwo
    //  } 
    // a += ", " + person.city + ", " + person.state + " " + person.zip
    let zip = (person.zip === '00000' || person.zip === '00000-0000' || person.zip === '000000000') ? '' : person.zip

    let a = `${person.addressOne} ${person.city}, ${person.state} ${zip}`
    a = a.replace(/null/g, "")

    a = a.trim()

    if (a === ',') {
      return "Not Available (*)"
    }

    return a
  }

  static propertyType(lot) {
    return propertyTypeLookup[lot.propertyUnitType]
  }

  static constructionType(lot) {
    return constructionLookup[lot.constructionCode]
  }

  static lotType(lot) {
    return lotTypeLookup[lot.lottype]
  }

    static proximityCodeType(code) {
      return proximityLookup[code]
    }


    static extensionType(lot) {
      return extensionLookup[lot.ext];
    }

    static bldgclassname(lot) {
        return bldgclassLookup[lot.bldgClass];
    }

    static fullBldgclassname(lot) {
      return fullBldgclassLookup[lot.bldgClass];
  }

    static borocode(lot) {
        const borocd = lot.cd;
        return `${borocd}`.substring(0, 1);
    }
    
    static boroname(lot) {
      if (lot.borough)
        return boroughLookup[lot.borough];
      else return ''
    }

    static boronameupper(borough) {      
      if (borough)
        return boroughLookup[borough]?.toUpperCase();
      else return ''
    }
    
    static boronameUpper(lot) {
        return this.boroname(lot)?.toUpperCase();
    }

  
    static bldgSizeString(lot) {
      return `${formatNumber(lot.bldgFront)} ft x ${formatNumber(lot.bldgDepth)} ft`
    }

    static lotSizeString(lot) {
      if (!this.isPluto(lot)) {
        let altFront = lot.altLotFront
        let altDepth = lot.altLotDepth

        let altFrontStr = ''
        let altDepthStr = ''
        if (altFront > 0 && altFront != lot.lotFront && lot.lotFront < lot.lotDepth) {
          altFrontStr = ` (${formatNumber(altFront)} ft)`
        }

        if (altDepth > 0 && altDepth != lot.lotDepth && lot.lotDepth < lot.lotFront) {
          altDepthStr = ` (${formatNumber(altDepth)} ft)`
        }
        return `${formatNumber(lot.lotFront)} ft${altFrontStr} x ${formatNumber(lot.lotDepth)} ft${altDepthStr}`
      } 
      return `${formatNumber(lot.lotFront)} ft x ${formatNumber(lot.lotDepth)} ft`
    }
    
    static cdName(lot) {
        const borocd = lot.cd;
        const boro = `${borocd}`.substring(0, 1);
        const cd = parseInt(`${borocd}`.substring(1, 3), 10).toString();
        return `${boroLookup[boro]} Community District ${cd}`;
    }
    
    static cdURLSegment(lot) {
        const borocd = lot.cd;
        const boro = `${borocd}`.substring(0, 1);
        const cleanBorough = boroLookup[boro].toLowerCase().replace(/\s/g, '-');
        const cd = parseInt(`${borocd}`.substring(1, 3), 10).toString();
        return `${cleanBorough}/${cd}`;
    }
    
    static landusename(lot) {
        return landuseLookup[lot.landuse];
    }
    
    static ownertypename(lot) {
        return ownertypeLookup[lot.ownertype];
    }
    
    static housenum(lot) {
        const match = lot.address.match(/([0-9-]*)\s[0-9A-Za-z\s]*/);
        return match ? match[1] : '';
    }
    
    static street(lot) {
        const match = lot.address.match(/[0-9-]*\s([0-9A-Za-z\s]*)/);
        return match ? match[1] : '';
    }
    
    static paddedZonemap(lot) {
        const zonemap = lot.zonemap;
        return (`0${zonemap}`).slice(-3);
    }

    // 569 AVENUE Y BROOKLYN NY 11235
    static fullAddress(lot) {
      if (!lot) return ''
      return `${lot.address}, ${lot.city}, ${lot.state} ${lot.zip}`
    }

    static tableDisplayAddress(lot) {
      if (!lot) return ''
      return `${lot.address}\n${lot.city}, ${lot.state} ${lot.zip}`
    }

    static dofLink(lot) {
      if (this.isPluto(lot))
        return `https://a836-pts-access.nyc.gov/care/datalets/datalet.aspx?mode=profileall2&UseSearch=no&pin=${lot.propertyId}&jur=65&taxyr=2021&LMparent=20`
      return ''
    }

    static biswebLink(lot) {
        //const BISWEB_HOST = 'http://a810-bisweb.nyc.gov/bisweb/PropertyBrowseByBBLServlet';
        const BISWEB_HOST = 'http://a810-bisweb.nyc.gov/bisweb/PropertyProfileOverviewServlet';

        //return `${BISWEB_HOST}?allborough=${lot.borocode}&allblock=${lot.block}&alllot=${lot.lot}&go5=+GO+&requestid=0`;
        return `${BISWEB_HOST}?boro=${lot.borocode}&block=${lot.block}&lot=${lot.lot}&go3=+GO+&requestid=0`
    }

    static biswebComplaintsLink(lot) {
      if (lot && !lot.bins) {
        return this.biswebLink(lot)
      }
      return `http://a810-bisweb.nyc.gov/bisweb/ComplaintsByAddressServlet?requestid=1&allbin=${lot && lot.bins && lot.bins.length > 0 ? lot.bins[0] : 0}`
    }

    static fullCommunityDistrictURL(lot) {
        return `https://communityprofiles.planning.nyc.gov/${this.cdURLSegment(lot)}`;
    }

 

  static digitalTaxMapLink(lot) {
    return `http://maps.nyc.gov/taxmap/map.htm?searchType=BblSearch&featureTypeName=EVERY_BBL&featureName=${lot.propertyId}`;
  }

  static zoningMapLink(lot) {
    return `http://www1.nyc.gov/assets/planning/download/pdf/zoning/zoning-maps/map${lot.zonemap}.pdf`;
  }

  static historicalZoningMapLink(lot) {
    return `http://www1.nyc.gov/assets/planning/download/pdf/zoning/zoning-maps/historical-zoning-maps/maps${this.paddedZonemap(lot)}.pdf`;
  }

  static ACRISLink(lot) {
    return `http://a836-acris.nyc.gov/bblsearch/bblsearch.asp?borough=${lot.borocode}&block=${lot.block}&lot=${lot.lot}`;
  }

  static housingInfoLink(lot) {
    if (!lot.borocode || !lot.address) {
      return ''
    }
    return `https://hpdonline.hpdnyc.org/Hpdonline/Provide_address.aspx?p1=${lot.borocode}&p2=${this.housenum(lot)}&p3=${this.street(lot)}&SearchButton=Search`;
  }
}

export default Lot