import React from 'react'
import moment from 'moment'

import { Grid, List, Divider, Popup, Placeholder, Dropdown, Segment, Label, Header } from 'semantic-ui-react'
import Lot from '../../Tax-Lot'
import { formatNumber, isEmpty } from '../../utility/Helper'
import { acris_document_viewer_url } from '../../utility/Config'
import { GridForPopup, PlaceholderStatSet } from '../extras/Misc'
import { EditableDropdown, EditableText } from '../extras/EditableInput'
import { constructionOptions, defaultOptions, proximityOptions } from '../../utility/Global'
import MTASubwayIcon from '../MTASubwayIcon'

import faConditions from '../../assets/json/fa/condition.json'
import floodZones from '../../assets/json/flood_zones.json'

const colWidthOne = 8
const colWidthTwo = 8

const BadgeSet = ({ color, title, hoverTitle, icon = 'certificate' }) => (
    <div className='badge-item' title={hoverTitle}>
        {/* <Icon className='badge-icon' size='bigger' color={color} name={icon} /> */}
        {/* <Label className='badge-label no-border' size='tiny' basic content={title} /> */}
        <Label className='badge-label' size='tiny' color={color} content={title} />
    </div>
)

const StatSet = ({ total, open, title, url }) => (
    <Segment className='stat-counter' circular>
        {url ? (
            <Label as='a' href={url}
                target="_blank"
                className={`color-${title.toLowerCase()}`} size='small' attached='top' content={title} />
        ) : (
            <Label className={`color-${title.toLowerCase()}`} size='small' attached='top' content={title} />
        )}
        <Header as='h4'>{open}</Header>
        <Header as='h5'>{total}</Header>
    </Segment>
)

const urlForStat = (title, lot) => {
    if (title === 'dob') {
        return Lot.biswebLink(lot)
    } else if (title === 'complaints') {
        return Lot.biswebComplaintsLink(lot)
    } else if (title === 'hpd') {
        return Lot.housingInfoLink(lot)
    }

    return null
}


const LotBadgesPanel = ({ lot }) => (
    <div className='badges-group'>
        {lot.vacateOrder && lot.vacateOrder.length > 0 ? (
            <BadgeSet
                color='red'
                hoverTitle={Lot.vacateText(lot.vacateOrder)}
                // icon='ban'
                title='HPD Vacate'
            />
        ) : null}
        {lot.hasAEP ? (
            <BadgeSet
                color='red'
                hoverTitle='AEP'
                // icon='shield'
                title='AEP'
            />
        ) : null}

        {lot.landmark ? (
            <BadgeSet
                // color='lightyellow'
                hoverTitle={lot.landmark}
                // icon='flag'
                title='Landmark'
            />
        ) : null}

        {lot.histdist ? (
            <BadgeSet
                // color='brown'
                hoverTitle={lot.histdist}
                // icon='university'
                title='Historic'
            />
        ) : null}
    </div>
)

const LotStatsPanel = ({ lot }) => (
    <div className='stats-group'>
        {lot.stats ? (
            <div>
                {Array.from(["DOB", "Complaints", "OATH", "HPD"], (e, i) => {
                    return <StatSet
                        key={i}
                        url={urlForStat(e.toLowerCase(), lot)}
                        total={lot.stats[e.toLowerCase()].total}
                        open={lot.stats[e.toLowerCase()].open}
                        title={e}
                    />
                })}
            </div>
        ) : (
            <div>
                {Array.from(["DOB", "Complaints", "OATH", "HPD"], (e, i) => {
                    return <PlaceholderStatSet key={i} title={e} />
                })}
            </div>
        )}
    </div>
)

const LotOwnerInformationPanel = ({ lot }) => (
    <Grid className='lot-details' textAlign='left' columns={2}>
        {lot.ownertype ? (
            <Grid.Row>
                <Grid.Column width={colWidthOne}>
                    <span>Owner Type</span>
                </Grid.Column>
                <Grid.Column width={colWidthTwo}>
                    {Lot.ownertypename(lot) ? (
                        <span>{Lot.ownertypename(lot)}</span>
                    ) : (
                        <span>Unknown/Private</span>
                    )}
                </Grid.Column>
            </Grid.Row>
        ) : null}



        <Grid.Row>
            <Grid.Column width={colWidthOne}>
                <span>Owner</span>
            </Grid.Column>
            <Grid.Column width={colWidthTwo}>
                <span className='nowrap' title={lot.ownerName}>{lot.ownerName}</span>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row>
            <Grid.Column width={6}>
                <span>Owner Address</span>
            </Grid.Column>
            <Grid.Column width={10}>
                <span className='nowrap' title={lot.ownerAddress}>{lot.ownerAddress}</span>
            </Grid.Column>
        </Grid.Row>
    </Grid>
)

const LotMiscInformationPanel = ({ lot }) => (
    <Grid className='lot-details' textAlign='left' columns={2}>

        <Grid.Row>
            <Grid.Column width={colWidthOne}>
                <span>Owner Occupied</span>
            </Grid.Column>
            <Grid.Column width={colWidthTwo}>
                <span>{lot.statusOwnerOccupiedFlag}</span>
            </Grid.Column>
        </Grid.Row>

        {lot.areaBuildingDefinitionCode ? (
            <Grid.Row>
                <Grid.Column width={colWidthOne}>
                    <span>Area Building Definition</span>
                </Grid.Column>
                <Grid.Column width={colWidthTwo}>
                    <span>{lot.areaBuildingDefinitionCode}</span>
                </Grid.Column>
            </Grid.Row>
        ) : null}

        <Grid.Row>
            <Grid.Column width={colWidthOne}>
                <span>Parking Carport</span>
            </Grid.Column>
            <Grid.Column width={colWidthTwo}>
                <span>{lot.parkingCarport && lot.parkingCarport === 1 ? 'Yes' : 'N/A'}</span>
            </Grid.Column>
        </Grid.Row>

        {lot.viewDescription ? (
            <Grid.Row>
                <Grid.Column width={colWidthOne}>
                    <span>View Description</span>
                </Grid.Column>
                <Grid.Column width={colWidthTwo}>
                    <span>{lot.viewDescription}</span>
                </Grid.Column>
            </Grid.Row>
        ) : null}

        {lot.topographyCode ? (
            <Grid.Row>
                <Grid.Column width={colWidthOne}>
                    <span>Topography</span>
                </Grid.Column>
                <Grid.Column width={colWidthTwo}>
                    <span>{lot.topographyCode}</span>
                </Grid.Column>
            </Grid.Row>
        ) : null}


        <Grid.Row>
            <Grid.Column width={colWidthOne}>
                <span>Description</span>
            </Grid.Column>
            <Grid.Column width={colWidthTwo}>
                <span>{lot.description}</span>
            </Grid.Column>
        </Grid.Row>

    </Grid>
)

const LotDocumentInformationPanel = ({ lot }) => (
    <Grid className='lot-details' textAlign='left' columns={2}>
        <Grid.Row>
            <Grid.Column width={colWidthOne}>
                <span><Popup content={
                    lot.sellers || lot.buyers ? (
                        <Grid>
                            <Grid.Row columns={2}>
                                <Grid.Column>
                                    <List size='small'>
                                        <List.Item>
                                            <List.Header>Sellers</List.Header>
                                        </List.Item>
                                        {lot.sellers?.map((_, index) => {
                                            return <List.Item key={index}>{_.name}</List.Item>
                                        })}
                                    </List>
                                </Grid.Column>
                                <Grid.Column>
                                    <List size='small'>
                                        <List.Item>
                                            <List.Header>Buyers</List.Header>
                                        </List.Item>
                                        {lot.buyers?.map((_, index) => {
                                            return <List.Item key={index}>{_.name}</List.Item>
                                        })}
                                    </List>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    ) : null
                } trigger={<a href={Lot.isPluto(lot) && Lot.ACRISLink(lot)} target="_blank" rel="noopener noreferrer">
                    Last Transfer
                </a>} /></span>
            </Grid.Column>
            <Grid.Column width={colWidthTwo}>
                {lot ?
                    (!isEmpty(lot.latestDeed) ? (
                        <span>
                            {(lot.latestDeed.transferred < 100 && lot.latestDeed.transferred > 0 ? (<span title='% Transferred'>({formatNumber(lot.latestDeed.transferred)}%) </span>) : null)}
                            {(lot.latestDeed.isMulti === true ? (<span title='Multi Parcel'>(M) </span>) : null)}
                            <a href={Lot.isPluto(lot) && acris_document_viewer_url + lot.latestDeed.id} target="_blank" rel="noopener noreferrer">
                                {lot.latestDeed.amount || lot.latestDeed.date ? (moment.utc(lot.latestDeed.date).format('L') + " (" + (lot.latestDeed.amount > 0 ? "$" + formatNumber(lot.latestDeed.amount) : "*") + ")") : "Not Available"}
                            </a>
                        </span>
                    )
                        : (<span>Not Available</span>)
                    ) : (
                        <Placeholder>
                            <Placeholder.Line length="short" />
                        </Placeholder>
                    )}
            </Grid.Column>
        </Grid.Row>

        <Grid.Row>
            <Grid.Column width={colWidthOne}>
                <span>
                    <Popup content={lot.firstLenders && lot.firstLenders.length > 0 ? (
                         <Grid>
                         <Grid.Row columns={lot.secondLenders ? 2 : 1}>
                             <Grid.Column>
                                 <List size='small'>
                                     <List.Item>
                                         <List.Header>Lenders</List.Header>
                                     </List.Item>
                                     {lot.firstLenders?.map((_, index) => {
                                         return <List.Item key={index}>{_.name}</List.Item>
                                     })}
                                 </List>
                             </Grid.Column>
                             {lot.secondLenders ? (
                                <Grid.Column>
                                <List size='small'>
                                    <List.Item>
                                        <List.Header>Second Lenders</List.Header>
                                    </List.Item>
                                    {lot.secondLenders?.map((_, index) => {
                                        return <List.Item key={index}>{_.name}</List.Item>
                                    })}
                                </List>
                            </Grid.Column>
                             ) : null}
                         </Grid.Row>
                     </Grid>
                        // <GridForPopup
                        //     title='Lenders'
                        //     list={lot.mersLender ? [{ name: lot.mersLender }] : lot.firstLenders}
                        // />
                    ) : null}

                        trigger={<a href={Lot.isPluto(lot) && Lot.ACRISLink(lot)} target="_blank" rel="noopener noreferrer">
                            Last Mortgage&nbsp;
                        </a>} />
                    {/* { lot.latestMortgageId ? (
                            <Popup content={lot.documentConnections?.parties && lot.documentConnections?.parties.length > 0 ? (
                            <GridForPopup
                            title='Connections'
                            list={lot.documentConnections?.parties}
                            titleKey='partyName'
                            keyName='name'
                            />
                           ) : 'Click to fetch'} 

                            trigger={ <Icon style={{cursor: 'pointer'}} onClick={isEmpty(lot.documentConnections) ? () => {handleFetchDocumentConnections(lot.latestMortgageId)} : null} name='eye' /> } />
                          ) : null } */}
                </span>
            </Grid.Column>
            <Grid.Column width={colWidthTwo}>
                {lot ?
                    (!isEmpty(lot.firstMortgage) ? (
                        <>
                            {Lot.isPluto(lot) ? (
                                <Popup position='right center'
                                    content={`Estimated Balance: ${lot.firstMortgage.amount && lot.balance != null ?
                                        `${lot.balance > 0 ?
                                            `$${formatNumber(lot.balance)}${lot.firstMortgage.total !== lot.firstMortgage.amount ? ' / $' + formatNumber(lot.firstMortgage.total) : ''}`
                                            : 'Paid Off'}`
                                        : 'Not Available'
                                        }`}
                                    trigger={
                                        <span>
                                            {(lot.firstMortgage.isSub && lot.firstMortgage.isSub === true ? (<span title='Subordinate'>(S) </span>) : null)}
                                            {(lot.firstMortgage.isMulti === true ? (<span title='Multi Parcel'>(M) </span>) : null)}
                                            {(lot.firstMortgage.isPurchaseMortgage === true ? (<span title='Purchase Mortgage'>(P) </span>) : null)}
                                            <a href={Lot.isPluto(lot) && acris_document_viewer_url + lot.firstMortgage.id} target="_blank" rel="noopener noreferrer">
                                                {lot.firstMortgage.amount ? (moment.utc(lot.firstMortgage.date).format('L') + " (" + (lot.firstMortgage.amount > 0 ? "$" + formatNumber(lot.firstMortgage.amount) : "*") + ")") : "Not Available"}
                                            </a>
                                        </span>
                                    }
                                />
                            ) :
                                <span>
                                    {(lot.firstMortgage.isCombined === true ? (<span title='Combined'>(C) </span>) : null)}
                                    {(lot.firstMortgage.isMulti === true ? (<span title='Multi Parcel'>(M) </span>) : null)}
                                    {lot.firstMortgage.amount ? (moment.utc(lot.firstMortgage.date).format('L') + " (" + (lot.firstMortgage.amount > 0 ? "$" + formatNumber(lot.firstMortgage.amount) : "*") + ")") : "Not Available"}
                                </span>
                            }
                            {!isEmpty(lot.secondMortgage) ? (
                                <span>
                                    {(lot.secondMortgage.isCombined === true ? (<span title='Combined'>(C) </span>) : null)}
                                    {(lot.secondMortgage.isSub === true ? (<span title='Subordinate'>(S) </span>) : null)}
                                    {(lot.secondMortgage.isMulti === true ? (<span title='Multi Parcel'>(M) </span>) : null)}
                                    {lot.secondMortgage.amount ? (moment.utc(lot.secondMortgage.date).format('L') + " (" + (lot.secondMortgage.amount > 0 ? "$" + formatNumber(lot.secondMortgage.amount) : "*") + ")") : "Not Available"}
                                </span>
                            ) : null}
                        </>
                    )
                        : (<span>Not Available</span>)
                    ) : (
                        <Placeholder>
                            <Placeholder.Line length="short" />
                        </Placeholder>
                    )}
            </Grid.Column>
        </Grid.Row>

        {lot && lot.listing ? (
            <Grid.Row>
                <Grid.Column width={colWidthOne}>
                    <span>
                        <Popup content={(
                            lot.listingDetails ?
                            <GridForPopup
                                title=''
                                list={[
                                    {
                                        label: 'Bldg Area',
                                        value: `${lot.listingDetails.bldgArea ? formatNumber(lot.listingDetails.bldgArea) : 'N/A'}`
                                    },
                                    {
                                        label: 'Lot Area',
                                        value: `${lot.listingDetails.lotArea ? formatNumber(lot.listingDetails.lotArea) : 'N/A'}`
                                    },
                                    {
                                        label: 'Bedrooms',
                                        value: `${lot.listingDetails.bedrooms ? lot.listingDetails.bedrooms : 'N/A'}`
                                    },
                                    {
                                        label: 'Bathrooms',
                                        value: `${lot.listingDetails.bathrooms ? parseFloat(lot.listingDetails.bathrooms) : 'N/A'}`
                                    },
                                    {
                                        label: 'Basement',
                                        value: `${lot.listingDetails.basement ? lot.listingDetails.basement : 'N/A'}`
                                    },
                                    {
                                        label: 'Stories',
                                        value: `${lot.listingDetails.stories ? parseFloat(lot.listingDetails.stories) : 'N/A'}`
                                    },
                                    {
                                        label: 'Neighborhood',
                                        value: `${lot.listingDetails.neighborhood ? lot.listingDetails.neighborhood : 'N/A'}`
                                    },
                                    {
                                        label: 'Zoning',
                                        value: `${lot.listingDetails.zoning ? lot.listingDetails.zoning : 'N/A'}`
                                    },
                                    {
                                        label: 'Year Built',
                                        value: `${lot.listingDetails.yearBuilt ? lot.listingDetails.yearBuilt : 'N/A'}`
                                    },
                                    {
                                        label: 'Exterior Construction',
                                        value: `${lot.listingDetails.exteriorConstruction ? lot.listingDetails.exteriorConstruction : 'N/A'}`
                                    },
                                    {
                                        label: 'Exterior Features',
                                        value: `${lot.listingDetails.exteriorFeatures ? lot.listingDetails.exteriorFeatures : 'N/A'}`
                                    },
                                    {
                                        label: 'Garage Type',
                                        value: `${lot.listingDetails.garageType ? lot.listingDetails.garageType : 'N/A'}`
                                    },
                                    {
                                        label: 'Garages',
                                        value: `${lot.listingDetails.garages ? lot.listingDetails.garages : 'N/A'}`
                                    },
                                    {
                                        label: 'School District',
                                        value: `${lot.listingDetails.schoolDistrict ? lot.listingDetails.schoolDistrict : 'N/A'}`
                                    },
                                ]}
                                titleKey='label'
                                keyName='value'
                            /> : null
                        )}
                            position='bottom center'
                            trigger={<span>
                                {lot?.listing?.date > lot?.latestDeed?.date ? lot.listing?.status : 'Last'} Listing&nbsp;
                            </span>} />
                    </span>
                </Grid.Column>
                <Grid.Column width={colWidthTwo}>
                    <span>
                        {lot.listing.amount ? (moment.utc(lot.listing.date).format('L') + " (" + (lot.listing.amount > 0 ? "$" + formatNumber(lot.listing.amount) : "*") + ")") : "Not Available"}
                    </span>
                </Grid.Column>
            </Grid.Row>
        ) : null}

    </Grid>
)

const LotInformationPanel = ({ className, lot, updateHandlers, handleWideStreetChange }) => (
    <Grid className={`lot-details ${className}`} textAlign='left' columns={2}>

        {lot.propertyType && (
            <Grid.Row>
                <Grid.Column width={colWidthOne}>
                    <span>Property Type</span>
                </Grid.Column>
                <Grid.Column width={colWidthTwo}>
                    <span>{lot.propertyType}</span>
                    {/* {lot && lot.listing && lot.listing.propertyType && lot.listing.propertyType.toUpperCase() !== lot.propertyUse.toUpperCase() ? <span>({lot.listing.propertyType})</span> : null} */}
                </Grid.Column>
            </Grid.Row>
        )}

        {lot.buildingClass && (
            <Grid.Row>
                <Grid.Column width={colWidthOne}>
                    <span>Building Class</span>
                </Grid.Column>
                <Grid.Column width={colWidthTwo}>
                    <span>{lot.buildingClass}</span>
                </Grid.Column>
            </Grid.Row>
        )}


        <Grid.Row>
            <Grid.Column width={colWidthOne}>
                <span>Total Residential</span>
            </Grid.Column>
            <Grid.Column width={colWidthTwo}>
                {lot && <EditableText originalValue={lot.residentialUnits} value={Lot.residentialUnits(lot)} onSaveHandler={updateHandlers && updateHandlers.length > 0 && updateHandlers[0]} />}
            </Grid.Column>
        </Grid.Row>

        {lot.totalUnits > lot.residentialUnits ? (
            <Grid.Row>
                <Grid.Column width={colWidthOne}>
                    <span>Total Units</span>
                </Grid.Column>
                <Grid.Column width={colWidthTwo}>
                    <span>{Lot.totalUnits(lot)}</span> {Lot.commericalUnits(lot) > 0 ? (
                        <span> ({Lot.commericalUnits(lot)} Commercial)</span>
                    ) : null}
                </Grid.Column>
            </Grid.Row>
        ) : null}

        {/* {lot.bldgClass && (
            <Grid.Row>
                <Grid.Column width={colWidthOne}>
                    <span>Building Class</span>
                </Grid.Column>
                <Grid.Column width={colWidthTwo}>
                    <span className='nowrap' title={Lot.bldgclassname(lot)}>( {lot.bldgClass} ) {Lot.bldgclassname(lot)}</span>
                </Grid.Column>
            </Grid.Row>
        )} */}


        {
            lot.condition && (
                <Grid.Row>
                    <Grid.Column width={colWidthOne}>
                        <span>Condition</span>
                    </Grid.Column>
                    <Grid.Column width={colWidthTwo}>
                        <span title={lot.condition}>{faConditions[lot.condition]}</span>
                    </Grid.Column>
                </Grid.Row>
            )
        }

        {lot.construction || lot.constructionCode ?
            <Grid.Row>
                <Grid.Column width={colWidthOne}>
                    <span>Construction</span>
                </Grid.Column>
                <Grid.Column width={colWidthTwo}>
                    {/* <span>{lot.construction}</span> */}
                    {Lot.isPluto(lot) ? (
                        <EditableDropdown originalValue={lot.construction} value={lot.construction} onSaveHandler={updateHandlers && updateHandlers.length > 3 && updateHandlers[3]} options={constructionOptions} />
                    ) : Lot.isFA(lot) ? (
                        <span>{Lot.lotConstruction(lot)}</span>
                    ) : <span>{lot.construction}</span>}
                </Grid.Column>
            </Grid.Row>
            : null}

        {lot && lot.style ? (
            <Grid.Row>
                <Grid.Column width={colWidthOne}>
                    <span>Style</span>
                </Grid.Column>
                <Grid.Column width={colWidthTwo}>
                    {lot.style ? (
                        <span >{lot.style}</span>
                    ) : <span>N/A</span>}
                </Grid.Column>
            </Grid.Row>
        ) : null}

        {lot && lot.exterior ? (
            <Grid.Row>
                <Grid.Column width={colWidthOne}>
                    <span>Exterior</span>
                </Grid.Column>
                <Grid.Column width={colWidthTwo}>
                    {lot.exterior ? (
                        <>
                            <span>{lot.exterior}</span>
                        </>
                    ) : <span>N/A</span>}
                </Grid.Column>
            </Grid.Row>
        ) : null}
        
        {lot && lot.proximity && lot.proximity !== 'N/A' ? <Grid.Row>
            <Grid.Column width={colWidthOne}>
                <span>Proximity</span>
            </Grid.Column>
            <Grid.Column width={colWidthTwo}>
                {/* <span>{Lot.proximityType(lot)}</span> */}
                {lot && <EditableDropdown valueLookup={Lot.proximityCodeType} originalValue={lot.proxCode} value={Lot.proxcode(lot)} onSaveHandler={updateHandlers && updateHandlers.length > 2 && updateHandlers[2]} options={proximityOptions} />}
            </Grid.Column>
        </Grid.Row> : null}

        <Grid.Row>
            <Grid.Column width={colWidthOne}>
                <span>Stories</span>
            </Grid.Column>
            <Grid.Column width={colWidthTwo}>
                <span>{lot.stories > 0 ? lot.stories : 'N/A'}</span>
                {lot && lot.listingDetails && lot.listingDetails.stories && parseFloat(lot.listingDetails.stories) !== parseFloat(lot.stories) ? <span>({parseFloat(lot.listingDetails.stories)})</span> : null}
            </Grid.Column>
        </Grid.Row>

        <Grid.Row>
            <Grid.Column width={colWidthOne}>
                <span>Building Sqft</span>
            </Grid.Column>
            <Grid.Column width={colWidthTwo}>
                {lot && <EditableText originalValue={lot.bldgArea} value={Lot.bldgarea(lot)} onSaveHandler={updateHandlers && updateHandlers.length > 1 && updateHandlers[1]} />}
            </Grid.Column>
        </Grid.Row>

        {lot.bldgFront && lot.bldgDepth && (
            <Grid.Row>
                <Grid.Column width={colWidthOne}>
                    <span>Building Dimensions</span>
                </Grid.Column>
                <Grid.Column width={colWidthTwo}>
                    <span>{Lot.bldgSizeString(lot)}</span>
                </Grid.Column>
            </Grid.Row>
        )}

        { lot.livingArea ? (
            <Grid.Row>
            <Grid.Column width={colWidthOne}>
                <span>Living Sqft</span>
            </Grid.Column>
            <Grid.Column width={colWidthTwo}>
                <span>{formatNumber(lot.livingArea)}</span>
            </Grid.Column>
            </Grid.Row>
        ) : null }

        { lot.faArea ? (
            <Grid.Row>
            <Grid.Column width={colWidthOne}>
                <span>FA Sqft</span>
            </Grid.Column>
            <Grid.Column width={colWidthTwo}>
                <span>{formatNumber(lot.faArea)}</span>
            </Grid.Column>
            </Grid.Row>
        ) : null }

        { lot.listing && lot.listingDetails ? (
            <Grid.Row>
            <Grid.Column width={colWidthOne}>
                <span>Listing Sqft</span>
            </Grid.Column>
            <Grid.Column width={colWidthTwo}>
                <span>{formatNumber(lot.listingDetails.bldgArea)}</span>
            </Grid.Column>
            </Grid.Row>
        ) : null }

        { lot.basementArea ? (
            <Grid.Row>
            <Grid.Column width={colWidthOne}>
                <span>Basement Sqft</span>
            </Grid.Column>
            <Grid.Column width={colWidthTwo}>
                <span>{formatNumber(lot.basementArea)}</span>
            </Grid.Column>
            </Grid.Row>
        ) : null }

        { lot.garageArea ? (
            <Grid.Row>
            <Grid.Column width={colWidthOne}>
                <span>Garage Sqft</span>
            </Grid.Column>
            <Grid.Column width={colWidthTwo}>
                <span>{formatNumber(lot.garageArea)}</span>
            </Grid.Column>
            </Grid.Row>
        ) : null }

        { lot.atticArea ? (
            <Grid.Row>
            <Grid.Column width={colWidthOne}>
                <span>Attic Sqft</span>
            </Grid.Column>
            <Grid.Column width={colWidthTwo}>
                <span>{formatNumber(lot.atticArea)}</span>
            </Grid.Column>
            </Grid.Row>
        ) : null }

        {lot.buildingHeight && (
            <Grid.Row>
                <Grid.Column width={colWidthOne}>
                    <span>Building Height</span>
                </Grid.Column>
                <Grid.Column width={colWidthTwo}>
                    <span>{formatNumber(lot.buildingHeight)} ft</span>
                </Grid.Column>
            </Grid.Row>
        )}

        {lot && lot.ext ? (
            <Grid.Row>
                <Grid.Column width={colWidthOne}>
                    <span>Extension</span>
                </Grid.Column>
                <Grid.Column width={colWidthTwo}>
                    {lot && (<> {
                        lot.ext && lot.hasExtension ? (
                            <span>Yes {lot.yearAlter2 && lot.yearAlter2 > 0 ? "(" + lot.yearAlter2 + ")" : lot.yearAlter1 && lot.yearAlter1 > 0 ? "(" + lot.yearAlter1 + ")" : ""}</span>
                        ) : (
                            <span>No</span>
                        )}</>)}

                </Grid.Column>
            </Grid.Row>
        ) : null}

        <Grid.Row>
            <Grid.Column width={colWidthOne}>
                <span>Bedrooms</span>
            </Grid.Column>
            <Grid.Column width={colWidthTwo}>
                <span>{Lot.bedrooms(lot) > 0 ? Lot.bedrooms(lot) : 'N/A'}</span>
                {lot && lot.listingDetails && lot.listingDetails.bedrooms && parseInt(lot.listingDetails.bedrooms) !== Lot.bedrooms(lot) ? <span>({parseInt(lot.listingDetails.bedrooms)})</span> : null}
            </Grid.Column>
        </Grid.Row>

        <Grid.Row>
            <Grid.Column width={colWidthOne}>
                <span>Bathrooms</span>
            </Grid.Column>
            <Grid.Column width={colWidthTwo}>
                <span>{lot.bathrooms && lot.bathrooms > 0 ? lot.bathrooms : 'N/A'}</span>
                {lot && lot.listingDetails && lot.listingDetails.bathrooms && parseInt(lot.listingDetails.bathrooms) !== lot.bathrooms ? <span>({parseFloat(lot.listingDetails.bathrooms)})</span> : null}
            </Grid.Column>
        </Grid.Row>

        <Grid.Row>
            <Grid.Column width={colWidthOne}>
                <span>Basement</span>
            </Grid.Column>
            <Grid.Column width={colWidthTwo}>
                <span>
                    {lot.hasBasement === null ? 'N/A' : lot.hasBasement ? 'Yes' : 'No'}
                </span>
            </Grid.Column>
        </Grid.Row>

        {typeof lot.hasAttic !== 'undefined' && (
            <Grid.Row>
                <Grid.Column width={colWidthOne}>
                    <span>Attic</span>
                </Grid.Column>
                <Grid.Column width={colWidthTwo}>
                    <span>
                        {lot.hasAttic === null ? 'N/A' : lot.hasAttic ? 'Yes' : 'No'}
                    </span>
                </Grid.Column>
            </Grid.Row>
        )}

        <Grid.Row>
            <Grid.Column width={colWidthOne}>
                <span>Garage</span>
            </Grid.Column>
            <Grid.Column width={colWidthTwo}>
                <span>
                    {lot.hasGarage === null ? 'N/A' : lot.hasGarage ? 'Yes' : 'No'}
                </span>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row>
            <Grid.Column width={colWidthOne}>
                <span>Year Built</span>
            </Grid.Column>
            <Grid.Column width={colWidthTwo}>
                <span>{lot.yearBuilt ? lot.yearBuilt : 'N/A'}</span>
                {lot && lot.listingDetails && lot.listingDetails.yearBuilt && lot.listingDetails.yearBuilt.toString() !== lot.yearBuilt.toString() ? <span>({lot.listingDetails.yearBuilt})</span> : null}
            </Grid.Column>
        </Grid.Row>

        <Grid.Row>
            <Grid.Column width={colWidthOne}>
                <span>Lot Sqft</span>
            </Grid.Column>
            <Grid.Column width={colWidthTwo}>
                {lot && lot.lotArea ? <span>{formatNumber(lot.lotArea)}</span> : null}
            </Grid.Column>
        </Grid.Row>

        {lot.lotFront && lot.lotDepth ? (
            <Grid.Row>
                <Grid.Column width={colWidthOne}>
                    <span>Lot Dimensions</span>
                </Grid.Column>
                <Grid.Column width={colWidthTwo}>
                    <span>{Lot.lotSizeString(lot)}</span>
                </Grid.Column>
            </Grid.Row>
        ) : null}

        <Divider />

        {lot.annualTax && (
            <Grid.Row>
            <Grid.Column width={colWidthOne}>
                <span><a href={Lot.isPluto(lot) && Lot.dofLink(lot)} target="_blank" rel="noopener noreferrer">
                    Annual Tax
                </a></span>
            </Grid.Column>
            <Grid.Column width={colWidthTwo}>
            <span>
                <a href={lot.propertyBillUrl && lot.propertyBillUrl} target="_blank" rel="noopener noreferrer">{lot.annualTax ? ("$" + formatNumber(lot.annualTax)) : "Not Available"}</a>
                {
                    lot.balancePastDue !== undefined
                        ? lot.balancePastDue > 0
                            ? <span> (${formatNumber(lot.balancePastDue)})</span>
                            : null
                        : null
                }
            </span>
            </Grid.Column>
        </Grid.Row>
        )}

        {lot.floodZone && (
            <Grid.Row>
                <Grid.Column width={colWidthOne}>
                    <span>Flood Zone</span>
                </Grid.Column>
                <Grid.Column width={colWidthTwo}>
                    <span className='nowrap' title={floodZones[lot.floodZone]}>{lot.floodZone}</span>
                </Grid.Column>
            </Grid.Row>
        )}

        {lot.floodZone && (
            <Grid.Row>
                <Grid.Column width={colWidthOne}>
                    <span>Flood Score</span>
                </Grid.Column>
                <Grid.Column width={colWidthTwo}>
                    <span className='nowrap'>{formatNumber(lot.floodScore)}</span>
                </Grid.Column>
            </Grid.Row>
        )}

        {lot && lot.schoolDistrict && (
            <Grid.Row>
                <Grid.Column width={colWidthOne}>
                    <span>School District</span>
                </Grid.Column>
                <Grid.Column width={colWidthTwo}>
                    <span className='nowrap' title={lot.schoolDistrict}>{lot.schoolDistrict}</span>
                </Grid.Column>
            </Grid.Row>
        )}

        {lot && lot.legalDescription && (
            <Grid.Row>
                <Grid.Column width={colWidthOne}>
                    <span>Legal Description</span>
                </Grid.Column>
                <Grid.Column width={colWidthTwo}>
                    <span className='nowrap' title={lot.legalDescription}>{lot.legalDescription}</span>
                </Grid.Column>
            </Grid.Row>
        )}

        <Grid.Row>
            <Grid.Column width={colWidthOne}>
                <span>Zoning</span>
            </Grid.Column>
            <Grid.Column width={colWidthTwo}>
                <span>{lot.zoning ? lot.zoning : 'N/A'}</span>
                {lot && lot.listingDetails && lot.listingDetails.zoning && lot.listingDetails.zoning !== lot.zoning ? <span>({lot.listingDetails.zoning})</span> : null}
            </Grid.Column>
        </Grid.Row>

        <Grid.Row>
            <Grid.Column width={colWidthOne}>
                <span>Pool</span>
            </Grid.Column>
            <Grid.Column width={colWidthTwo}>
                <span>
                    {lot.hasPool === null ? 'N/A' : lot.hasPool ? 'Yes' : 'No'}
                </span>
            </Grid.Column>
        </Grid.Row>

        {lot && lot.siteInfluenceCode ? (
            <Grid.Row>
                <Grid.Column width={colWidthOne}>
                    <span>Site Influence</span>
                </Grid.Column>
                <Grid.Column width={colWidthTwo}>
                    <span title={lot.siteInfluenceCode}>{Lot.siteInfluences(lot)}</span>
                </Grid.Column>
            </Grid.Row>
        ) : null}

        {lot && lot.amenities ? (
            <Grid.Row>
                <Grid.Column width={colWidthOne}>
                    <span>Amenities</span>
                </Grid.Column>
                <Grid.Column width={colWidthTwo}>
                    <span title={lot.amenities}>{Lot.amenities(lot)}</span>
                </Grid.Column>
            </Grid.Row>
        ) : null}

        {Lot.isPluto(lot) ? (
            <>
                <Grid.Row>
                    <Grid.Column width={colWidthOne}>
                        <span>Corner</span>
                    </Grid.Column>
                    <Grid.Column width={colWidthTwo}>
                        {lot && (<> {
                            <span>{lot.isCorner ? 'Yes' : 'No'}</span>
                        }</>)}
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                    <Grid.Column width={colWidthOne}>
                        <span>Irregular</span>
                    </Grid.Column>
                    <Grid.Column width={colWidthTwo}>
                        {lot && <span>{lot.isIrregular ? 'Yes' : 'No'}</span>}
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                    <Grid.Column width={colWidthOne}>
                        <span>Wide Street</span>
                    </Grid.Column>
                    <Grid.Column width={colWidthTwo}>
                        {lot && (
                            <>
                                {handleWideStreetChange ? (
                                    <span className='wide-street-dropdown' title={lot?.streets?.length > 1 ? 'More than one street attached to this lot' : lot?.streets?.length === 0 ? 'No streets attached to this lot' : ''}>
                                        <Dropdown
                                            disabled={lot?.streets?.length > 1 || lot?.streets?.length === 0}
                                            className='no-border'
                                            inline
                                            placeholder='?'
                                            fluid
                                            selection
                                            options={defaultOptions}
                                            value={lot?.isWideStreet}
                                            onChange={(e, { value }) => { handleWideStreetChange(value, lot) }}
                                        />
                                    </span>
                                ) :
                                    (
                                        <span>{lot?.isWideStreet ? 'Yes' : 'No'}</span>
                                    )}
                            </>
                        )}
                    </Grid.Column>
                </Grid.Row>
            </>
        ) : null}

    </Grid>
)

const LotFooterInformationPanel = ({ lot }) => (
    <Grid className="lot-details" textAlign='left' columns={2}>

        {Lot.isPluto(lot) &&
            <Grid.Row>
                <Grid.Column width={colWidthOne}>
                    <span>Distance to Subway</span>
                </Grid.Column>
                <Grid.Column width={colWidthTwo}>
                    <span>{(formatNumber(lot.distanceToSubway))} Miles | {lot.subwayStation} {lot.subwayStationLine && <MTASubwayIcon subwayLine={lot.subwayStationLine} />}</span>
                </Grid.Column>
            </Grid.Row>}

        {/* 
              <Grid.Row>
                <Grid.Column width={colWidthOne}>
                  <span>Distance to Park</span>
                </Grid.Column>
                <Grid.Column width={colWidthTwo}>
                  <span>{(formatNumber(lot.closestParkDistance))} Miles | {lot.closestParkName} </span>
                </Grid.Column>
              </Grid.Row> */}


    </Grid>
)

const CityDataRow = ({ lot }) => {
    const offset = 4
    const { data, saleData } = lot?.cityData || {}
    return (
        <Grid className="lot-details" textAlign='left' columns={2}>
            <>
                {data ? (
                    <>

                        <Grid.Row>
                            <Grid.Column width={colWidthOne + offset}>
                                <span>Total 1-4 Unit Homes</span>
                            </Grid.Column>
                            <Grid.Column width={colWidthTwo - offset}>
                                <span>{formatNumber(data.total)}</span>
                            </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                            <Grid.Column width={colWidthOne + offset}>
                                <span>Total {lot.residentialUnits === 0 ? 1 : lot.residentialUnits} Unit Homes</span>
                            </Grid.Column>
                            <Grid.Column width={colWidthTwo - offset}>
                                <span>{formatNumber(data.unit)}</span>
                            </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                            <Grid.Column width={colWidthOne + offset}>
                                <span>Total {'->'} {lot.stories} Stories Homes</span>
                            </Grid.Column>
                            <Grid.Column width={colWidthTwo - offset}>
                                <span>{formatNumber(data.stories)}</span>
                            </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                            <Grid.Column width={colWidthOne + offset}>
                                <span>Total {'->->'} {lot.style ? lot.style : 'Style'} Homes</span>
                            </Grid.Column>
                            <Grid.Column width={colWidthTwo - offset}>
                                <span>{formatNumber(data.style)}</span>
                            </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                            <Grid.Column width={colWidthOne + offset}>
                                <span>Total {'->->->'} {Lot.lotConstruction(lot) ? Lot.lotConstruction(lot) : 'Construction'} Homes</span>
                            </Grid.Column>
                            <Grid.Column width={colWidthTwo - offset}>
                                <span>{formatNumber(data.construction)}</span>
                            </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                            <Grid.Column width={colWidthOne + offset}>
                                <span>Total {'->->->->'} {lot.exterior ? lot.exterior : 'Exterior'} Homes</span>
                            </Grid.Column>
                            <Grid.Column width={colWidthTwo - offset}>
                                <span>{formatNumber(data.exterior)}</span>
                            </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                            <Grid.Column width={colWidthOne + offset}>
                                <span>Total {'->->->->->'} Homes in Zip {lot.zip}</span>
                            </Grid.Column>
                            <Grid.Column width={colWidthTwo - offset}>
                                <span>{formatNumber(data.zip)}</span>
                            </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                            <Grid.Column width={colWidthOne + offset}>
                                <span>Total {'->->->->->->'} Homes within 1 Mile</span>
                            </Grid.Column>
                            <Grid.Column width={colWidthTwo - offset}>
                                <span>{formatNumber(data.radius)}</span>
                            </Grid.Column>
                        </Grid.Row>

                        <Divider />
                    </>
                ) : null}

                {saleData ? (
                    <>
                        <Grid.Row>
                            <Grid.Column width={colWidthOne + offset}>
                                <span>Total 1-4 Unit Sales</span>
                            </Grid.Column>
                            <Grid.Column width={colWidthTwo - offset}>
                                <span>{formatNumber(saleData.total)}</span>
                            </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                            <Grid.Column width={colWidthOne + offset}>
                                <span>Total {lot.residentialUnits === 0 ? 1 : lot.residentialUnits} Unit Sales</span>
                            </Grid.Column>
                            <Grid.Column width={colWidthTwo - offset}>
                                <span>{formatNumber(saleData.unit)}</span>
                            </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                            <Grid.Column width={colWidthOne + offset}>
                                <span>Total {'->'} {lot.stories} Stories Sales</span>
                            </Grid.Column>
                            <Grid.Column width={colWidthTwo - offset}>
                                <span>{formatNumber(saleData.stories)}</span>
                            </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                            <Grid.Column width={colWidthOne + offset}>
                                <span>Total {'->->'} {lot.style ? lot.style : 'Style'} Sales</span>
                            </Grid.Column>
                            <Grid.Column width={colWidthTwo - offset}>
                                <span>{formatNumber(saleData.style)}</span>
                            </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                            <Grid.Column width={colWidthOne + offset}>
                                <span>Total {'->->->'} {Lot.lotConstruction(lot) ? Lot.lotConstruction(lot) : 'Construction'} Sales</span>
                            </Grid.Column>
                            <Grid.Column width={colWidthTwo - offset}>
                                <span>{formatNumber(saleData.construction)}</span>
                            </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                            <Grid.Column width={colWidthOne + offset}>
                                <span>Total {'->->->->'} {lot.exterior ? lot.exterior : 'Exterior'} Sales</span>
                            </Grid.Column>
                            <Grid.Column width={colWidthTwo - offset}>
                                <span>{formatNumber(saleData.exterior)}</span>
                            </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                            <Grid.Column width={colWidthOne + offset}>
                                <span>Total {'->->->->->'} Homes in Zip {lot.zip} Sales</span>
                            </Grid.Column>
                            <Grid.Column width={colWidthTwo - offset}>
                                <span>{formatNumber(saleData.zip)}</span>
                            </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                            <Grid.Column width={colWidthOne + offset}>
                                <span>Total {'->->->->->->'} Homes Within 1 Mile Sales</span>
                            </Grid.Column>
                            <Grid.Column width={colWidthTwo - offset}>
                                <span>{formatNumber(saleData.radius)}</span>
                            </Grid.Column>
                        </Grid.Row>

                    </>
                ) : null}
            </>
        </Grid>
    )
}

const InformationRow = ({ style, element, extra = null }) => (
    <Grid.Row style={style}>
        <Grid.Column width={colWidthOne}>
            <span style={element.titleStyle}>{element.title}</span>
        </Grid.Column>
        <Grid.Column textAlign='right' width={extra ? colWidthTwo / 2 : colWidthTwo}>
            <span style={element.valueStyle}>
                {element.valueUrl ? (
                    <a href={element.valueUrl} target="_blank" rel="noopener noreferrer">
                        {element.value}
                    </a>
                ) : (
                    <>
                        {element.value}
                    </>
                )}
            </span>
        </Grid.Column>
        {extra && (
            <Grid.Column width={colWidthTwo / 2}>
                <span>{extra?.value}</span>
            </Grid.Column>
        )}
    </Grid.Row>
)

const InformationPanel = ({ className, info, extra = null }) => (
    <Grid className={className} textAlign='left' columns={2}>
        {info.map((element, index) => (
            <InformationRow key={index} element={element} extra={extra} />
        ))}
    </Grid>
)

const DividedInformationPanel = ({ className, info, extra = null }) => (
    <Grid className={className} textAlign='left' columns={2}>
        {info.map((element, index) => (
            <>
                {element.map((item, index) => {
                    return <InformationRow key={index} element={item} extra={extra} />
                })
                }
                {index < info.length - 1 && <Divider />}
            </>
        ))}
    </Grid>
)

const ZillowEstimatesPanel = ({ lot }) => (
    <>
        {/* <Grid.Row>
            <Grid.Column width='16'>
                <Image src='/zillow-logo.png' size='tiny' href='http://www.zillow.com/' target='_blank' rel="noopener noreferrer" title='Real Estate on Zillow' />
            </Grid.Column>
        </Grid.Row> */}
        <Grid.Row style={{ paddingBottom: '0' }}>
            <Grid.Column width={colWidthOne}>
                <span>Zestimate® valuation</span>
            </Grid.Column>
            <Grid.Column textAlign='right' width={colWidthTwo}>
                <span>
                    {lot.zestimate !== undefined
                        ? lot.zestimate && lot.zestimate.zestimate && lot.zestimate.zestimate > 0
                            ? <a href={lot.zestimate.zillowUrl} title='See more details for this property on Zillow' target='_blank' rel="noopener noreferrer">${formatNumber(lot.zestimate.zestimate)}</a> :
                            'N/A'
                        : <Placeholder>
                            <Placeholder.Line length="short" />
                        </Placeholder>
                    }
                </span>
            </Grid.Column>
        </Grid.Row>
        <Grid.Row style={{ paddingTop: '0' }}>
            <Grid.Column width={colWidthOne}>
                <span>Rental Zestimate®</span>
            </Grid.Column>

            <Grid.Column textAlign='right' width={colWidthTwo}>
                <span>
                    {lot.zestimate !== undefined
                        ? lot.zestimate && lot.zestimate.rentalZestimate && lot.zestimate.rentalZestimate > 0
                            ? <span>${formatNumber(lot.zestimate.rentalZestimate)}</span> :
                            'N/A'
                        : <Placeholder>
                            <Placeholder.Line length="short" />
                        </Placeholder>
                    }
                </span>
            </Grid.Column>
        </Grid.Row>
        {/* <Grid.Row>
            <Grid.Column floated='right' width='8'>
                <span style={{ fontSize: '.75em' }}>Data provided 'as is' via the Zestimates API</span>
                <span style={{ fontSize: '.75em' }}>© Zillow, Inc., 2006-2020. Use is subject to <a href='https://bridgedataoutput.com/zillowterms' target='_blank' rel="noopener noreferrer">Terms of Use</a></span>
            </Grid.Column>
        </Grid.Row> */}
    </>
)

export {
    LotOwnerInformationPanel, LotDocumentInformationPanel, LotInformationPanel, LotFooterInformationPanel, LotMiscInformationPanel,
    InformationPanel, DividedInformationPanel, InformationRow, ZillowEstimatesPanel, LotBadgesPanel, LotStatsPanel, CityDataRow
}