export const SiteID = process.env.REACT_APP_SITE_ID

export const BaseURL = process.env.REACT_APP_SSO_LOGOUT_URI

export const PropertyAPIPathURL = process.env.REACT_APP_API_PATH
export const IntelAPIPathURL = process.env.REACT_APP_INTEL_API_PATH
export const UrbynAPIPathURL = process.env.REACT_APP_URBYN_API_PATH

export const mapbox_key = process.env.REACT_APP_MAPBOX_KEY

export const ManageProfileURL = process.env.REACT_APP_SSO_AUTHORITY_URL+"/Identity/Account/Manage"

export const acris_document_viewer_url = "https://a836-acris.nyc.gov/DS/DocumentSearch/DocumentImageView?doc_id="
export const acris_document_details_url = "https://a836-acris.nyc.gov/DS/DocumentSearch/DocumentDetail?doc_id="

export const property_api_urbyn_search_address_url = PropertyAPIPathURL+"/address/autocomplete"

export const property_api_external_information_url = UrbynAPIPathURL+"/get-external-property-information" // param = bbl
export const property_api_add_to_watchlist_url = PropertyAPIPathURL+"/add-to-watchlist" // param = bbl
export const property_api_add_to_ownership_url = PropertyAPIPathURL+"/add-to-ownership" // param = bbl
export const property_api_add_to_contract_url = PropertyAPIPathURL+"/add-to-contract" // param = bbl

export const property_api_vacate_order_url = UrbynAPIPathURL+"/get-vacate-order" // param = bbl
export const property_api_aep_url = UrbynAPIPathURL+"/get-aep" // param = bbl
export const property_api_annual_tax_url = UrbynAPIPathURL+"/get-annual-tax" // param = bbl
export const property_api_property_bill_url = UrbynAPIPathURL+"/get-property-bill-url" // param = bbl
export const property_api_stats_url = UrbynAPIPathURL+"/get-stats" // param = bbl
export const property_api_balance_past_due_url = UrbynAPIPathURL+"/get-balance-past-due" // param = bbl
export const property_api_zestimate_url = UrbynAPIPathURL+"/get-zestimate" // param = bbl
export const property_api_rents_url = UrbynAPIPathURL+"/get-rents-for-bbl" // param = bbl

export const property_api_fred_url = UrbynAPIPathURL+"/get-fred-price"

export const parcel_search_url = UrbynAPIPathURL+"/search-parcel-address"
export const parcel_get_url = UrbynAPIPathURL+"/get-parcel-by-address"

export const property_api_urbyn_search_re_address_url = UrbynAPIPathURL+"/search"
export const property_api_run_all_comps_url = UrbynAPIPathURL+"/run-all-comps" // 
export const property_api_run_comps_url = UrbynAPIPathURL+"/run-comps" // 
export const property_api_run_historical_comp_price_url = UrbynAPIPathURL+"/run-historical-comp-price" // 
export const property_api_run_ai_comps_url = UrbynAPIPathURL+"/run-ai-valuation" // 
export const property_api_run_simple_ai_comps_url = UrbynAPIPathURL+"/run-simple-ai-valuation" // 
export const property_api_run_ai_city_comps_url = UrbynAPIPathURL+"/run-ai-city-comps" // 
export const property_api_run_individual_comps_url = UrbynAPIPathURL+"/run-individual-comps" // 
export const property_api_get_lot_url = UrbynAPIPathURL+"/get-lot" // param bbl
export const property_api_get_neighbors_url = UrbynAPIPathURL+"/get-neighbors" // param lnga lata lngb latb
export const property_api_get_empty_streets_url = UrbynAPIPathURL+"/get-empty-streets" // param lnga lata lngb latb
export const property_api_run_prospects_url = UrbynAPIPathURL+"/run-prospects" // 
export const property_api_run_historical_url = UrbynAPIPathURL+"/run-historical-comps" // 
export const property_api_run_combined_comps_url = UrbynAPIPathURL+"/run-combined-comps" //

export const property_api_run_lender_summary_url = UrbynAPIPathURL+"/run-lender-summary"
export const property_api_run_lender_txs_url = UrbynAPIPathURL+"/run-lender-transactions"
export const property_api_run_borrower_txs_url = UrbynAPIPathURL+"/run-borrower-transactions-name"
export const property_api_run_borrowers_by_address_url = UrbynAPIPathURL+"/run-borrowers-by-address"

export const property_api_get_city_data_url = UrbynAPIPathURL+"/get-city-data"
export const property_api_get_city_sale_data_url = UrbynAPIPathURL+"/get-city-sale-data"

export const property_api_run_city_comps_url = UrbynAPIPathURL+"/run-city-comps"

export const property_api_run_history_appraisals_url = UrbynAPIPathURL+"/get-valuation-history"

export const property_api_get_document_connections_url = UrbynAPIPathURL+"/get-document-connections" // bbl

export const property_api_get_neighborhood_trends_url = UrbynAPIPathURL+'/get-neighborhood-trends'
export const property_api_get_neighborhood_sales_url = UrbynAPIPathURL+'/get-neighborhood-sales'
export const property_api_get_neighborhood_loans_url = UrbynAPIPathURL+'/get-neighborhood-loans'

export const property_api_update_lot_notes_url = UrbynAPIPathURL+"/update-lot-notes"
export const property_api_update_wide_street_url = UrbynAPIPathURL+"/update-wide-street"

export const property_api_get_ai_narrative_url = UrbynAPIPathURL+"/run-ai-narrative-by-id"

export const property_api_update_unitsres_url = UrbynAPIPathURL+"/update-lot-units-res"
export const property_api_update_bldgarea_url = UrbynAPIPathURL+"/update-lot-bldgarea"
export const property_api_update_proxcode_url = UrbynAPIPathURL+"/update-lot-proxcode"
export const property_api_update_construction_url = UrbynAPIPathURL+"/update-lot-construction"

export const get_report_url = PropertyAPIPathURL+"/get-report"
export const property_api_extract_addresses_from_pdf_url = PropertyAPIPathURL+"/extract-appraisal-addresses-from-pdf"

export const intel_connections_url = IntelAPIPathURL+'/get-connections'
export const intel_investor_connections_url = IntelAPIPathURL+'/get-investors'
export const intel_investor_info_url = IntelAPIPathURL+'/get-investor-lots'
export const intel_investor_parcels_url = IntelAPIPathURL+'/get-parcel-info'
