import React from 'react';

const SearchSpinner = ({ size = '14px', color = '#4183c4' }) => {
  // Convert size to number for calculations
  const numericSize = parseInt(size, 10);
  
  return (
    <div style={{ width: size, height: size, display: 'inline-block' }}>
      <svg 
        width={size} 
        height={size} 
        viewBox="0 0 24 24" 
        xmlns="http://www.w3.org/2000/svg"
      >
        <style>{`
          @keyframes spinnerRotate {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
          .spinner-circle {
            animation: spinnerRotate 0.8s linear infinite;
            transform-origin: center;
          }
        `}</style>
        <g className="spinner-circle">
          <circle 
            cx="12" 
            cy="12" 
            r="10"
            fill="none"
            stroke={color}
            strokeWidth="2.5"
            strokeDasharray="30 65"
            strokeLinecap="round"
          />
        </g>
      </svg>
    </div>
  );
};

export default SearchSpinner;